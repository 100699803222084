<template>
  <v-card class="mx-auto">
    <v-toolbar color="primary" dark flat>
      <v-toolbar-title>{{ $t("__items") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn class="ml-2" outlined @click="toCreateItem">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <v-data-table :headers="headers" :items="items">
        <template v-slot:[`item.photo`]="{ item }">
          <img :src="item.photo" width="100" class="py-3" v-if="item.photo" />
          <p class="text-center" v-else>-</p>
        </template>
        <template v-slot:[`item.data.name`]="{ item }">
          <v-btn block text color="primary" @click="toItemTelegramBot(item.id)">
            {{ item.data.name }}</v-btn
          >
          <div class="text-center">
            <v-tooltip top v-if="item.published">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click="copyLink(item.id)"
                  ><v-icon color="primary" small
                    >mdi-content-copy</v-icon
                  ></v-btn
                >
              </template>
              <span>{{ $t("__copy_item_link") }}</span>
            </v-tooltip>
            <v-tooltip top v-if="item.published">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click="showQrcode(item)"
                  ><v-icon color="primary" small>mdi-qrcode</v-icon></v-btn
                >
              </template>
              <span>{{ $t("__item_qrcode") }}</span>
            </v-tooltip>
          </div>
        </template>
        <template v-slot:[`item.edit`]="{ item }">
          <v-icon
            class="ma-2"
            small
            color="darken-2"
            @click="toUpdateItem(item)"
            >mdi-pencil</v-icon
          >
        </template>
        <template v-slot:[`item.published`]="{ item }">
          <template v-if="item.published">
            <v-chip class="ma-1" label small color="green white--text">
              {{ $t("__itemlist_published_published") }}
            </v-chip>
            <v-btn class="ma-1" x-small @click="toDelistItem(item)">{{
              $t("__itemlist_delist")
            }}</v-btn>
          </template>
          <template v-else>
            <v-chip class="ma-1" label small color="red white--text">
              {{ $t("__itemlist_published_delisted") }}
            </v-chip>
            <v-btn class="ma-1" x-small @click="toPublishItem(item)">{{
              $t("__itemlist_publish")
            }}</v-btn>
          </template>
        </template>
        <template v-slot:[`item.categories`]="{ item }">
          <v-chip
            label
            small
            class="ma-1"
            v-for="category in item.categories"
            :key="category.id"
          >
            {{ category.data.name }}
          </v-chip>
        </template>
        <template v-slot:[`item.price`]="{ item }">${{ item.price }}</template>
        <template v-slot:[`item.quantityOfStock`]="{ item }">
          <template v-if="item.unlimitedSupply">-</template>
          <template v-else-if="item.quantityOfStock > 0">{{
            item.quantityOfStock
          }}</template>
          <template v-else>
            <v-chip label small color="red white--text">
              {{ $t("__itemlist_quantityofstock_soldout") }}
            </v-chip>
          </template>
        </template>
        <template v-slot:[`item.delete`]="{ item }">
          <v-icon
            class="ma-2"
            small
            color="red darken-2"
            @click="toDeleteItem(item)"
            >mdi-delete</v-icon
          >
        </template>
      </v-data-table>
    </v-card-text>
    <v-dialog v-model="itemFormShow" persistent max-width="800px">
      <ItemForm
        :action="itemFormAction"
        :itemData="itemFormItemData"
        @ok="itemFormOk"
        @cancel="itemFormCancel"
      />
    </v-dialog>
    <AlertDialog
      :show="alertDialogShow"
      :title="alertDialogTitle"
      :text="alertDialogText"
      :oktext="$t('__confirm')"
      :canceltext="$t('__cancel')"
      @ok="alertDialogOk"
      @cancel="alertDialogCancel"
    />
    <alert
      :title="alertTitle"
      :color-type="alertColor"
      :alert-open="alertShow"
      :dismissible="alertDismissible"
    />
    <v-dialog max-width="400px" v-model="qrcodeDialogShow" persistent>
      <QRcodeDialog
        :type="'item'"
        :title="qrcodeDialogTitle"
        :telegramBotID="telegramBotID"
        :link="qrcodeDialogLink"
        @close="closeQrcode"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import ItemForm from "@/components/Store/ItemForm";
import AlertDialog from "@/components/AlertDialog";
import QRcodeDialog from "@/components/QRcodeDialog";

export default {
  components: {
    ItemForm,
    AlertDialog,
    QRcodeDialog
  },
  props: {
    filterCategoryIDs: {
      type: Array
    }
  },
  computed: {
    ...mapGetters({
      storeCategories: "store/categories",
      storeItems: "store/items",
      publishedItemQuantity: "store/publishedItemQuantity",
      telegramBotData: "store/messengerTelegramBotData"
    }),
    items() {
      const items = [];
      for (const storeItem of this.storeItems) {
        const item = {
          id: storeItem.id,
          data: storeItem.data,
          categories: []
        };
        item.published = storeItem.data.published_time
          ? storeItem.data.published_time.nanoseconds !== 0
          : false;
        if (item.data["category_ids"]) {
          for (const category of this.storeCategories) {
            if (item.data["category_ids"].includes(category.id))
              item.categories.push(category);
          }
          item.categories.sort((a, b) => {
            if (a.data.name < b.data.name) return -1;
            if (a.data.name > b.data.name) return 1;
            return 0;
          });
        }
        item.price = storeItem.data.price ? storeItem.data.price : 0;
        item.unlimitedSupply = storeItem.data.unlimited_supply;
        item.quantityOfStock = storeItem.data.quantity_of_stock
          ? storeItem.data.quantity_of_stock
          : 0;
        item.photo = storeItem.data.photo_urls[0]
          ? storeItem.data.photo_urls[0]
          : null;
        items.push(item);
      }
      return items;
    },
    telegramBotID() {
      return this.telegramBotData ? this.telegramBotData["bot_id"] : "";
    }
  },
  data() {
    return {
      headers: [
        {
          text: this.$t("__itemlist_photo"),
          value: "photo",
          sortable: false
        },
        {
          text: this.$t("__itemlist_name"),
          value: "data.name",
          align: "start"
        },
        {
          text: this.$t("__itemlist_edit"),
          value: "edit",
          align: "start",
          sortable: false
        },
        {
          text: this.$t("__itemlist_published"),
          value: "published",
          align: "start"
        },
        {
          text: this.$t("__itemlist_categories"),
          value: "categories",
          align: "start",
          sortable: false,
          filter: value => {
            if (!this.filterCategoryIDs) return true;
            if (this.filterCategoryIDs.length == 0) return true;
            for (const filterCategoryID of this.filterCategoryIDs) {
              if (
                value.findIndex(category => category.id == filterCategoryID) < 0
              )
                return false;
            }
            return true;
          }
        },
        {
          text: this.$t("__itemlist_price"),
          value: "price",
          align: "start"
        },
        {
          text: this.$t("__itemlist_quantityofstock"),
          value: "quantityOfStock",
          align: "start"
        },
        {
          text: this.$t("__itemlist_delete"),
          value: "delete",
          align: "end",
          sortable: false
        }
      ],
      itemFormShow: false,
      itemFormAction: "create",
      itemFormItemID: "",
      itemFormItemData: null,
      alertDialogShow: false,
      alertDialogTitle: "",
      alertDialogText: "",
      alertDialogFunc: null,
      alertDialogData: null,
      alertTitle: "",
      alertShow: false,
      alertColor: "error",
      alertDismissible: false,
      qrcodeDialogShow: false,
      qrcodeDialogTitle: null,
      qrcodeDialogLink: ""
    };
  },
  methods: {
    getCategoryName(categoryId) {
      const category = this.categories.find(c => c.id === categoryId);
      return category ? category.data.name : "";
    },
    toReloadItems() {
      this.$emit("reloadItems");
    },
    toCreateItem() {
      if (
        this.items.length < this.publishedItemQuantity ||
        this.publishedItemQuantity === -1
      ) {
        this.itemFormAction = "create";
        this.itemFormItemID = "";
        this.itemFormItemData = null;
        this.itemFormShow = true;
      } else {
        this.alertShow = true;
        this.alertDismissible = true;
        this.alertColor = "error";
        this.alertTitle = this.$t("__itemlist_published_limits");
        setTimeout(() => {
          this.alertShow = false;
          this.alertTitle = "";
        }, 5000);
      }
    },
    toUpdateItem(item) {
      this.itemFormAction = "update";
      this.itemFormItemID = item.id;
      this.itemFormItemData = item.data;
      this.itemFormShow = true;
    },
    toPublishItem(item) {
      if (!item.unlimitedSupply && item.quantityOfStock == 0) {
        alert(
          this.$t("__publishitem_soldout_message").replace("%1", item.data.name)
        );
      } else {
        this.alertDialogTitle = this.$t("__publishitem_title");
        this.alertDialogText = this.$t("__publishitem_text").replace(
          "%1",
          item.data.name
        );
        this.alertDialogFunc = this.publishItem;
        this.alertDialogData = item;
        this.alertDialogShow = true;
      }
    },
    publishItem(item) {
      this.$emit("publishItem", item.id);
    },
    toDelistItem(item) {
      this.alertDialogTitle = this.$t("__delistitem_title");
      this.alertDialogText = this.$t("__delistitem_text").replace(
        "%1",
        item.data.name
      );
      this.alertDialogFunc = this.delistItem;
      this.alertDialogData = item;
      this.alertDialogShow = true;
    },
    delistItem(item) {
      this.$emit("delistItem", item.id);
    },
    toDeleteItem(item) {
      this.alertDialogTitle = this.$t("__deleteitem_title");
      this.alertDialogText = this.$t("__deleteitem_text").replace(
        "%1",
        item.data.name
      );
      this.alertDialogFunc = this.deleteItem;
      this.alertDialogData = item;
      this.alertDialogShow = true;
    },
    deleteItem(item) {
      this.$emit("deleteItem", item.id);
    },
    itemFormOk({
      name,
      description,
      introduction,
      categories,
      photoURLs,
      price,
      quantityOfStock,
      variations
    }) {
      this.itemFormShow = false;
      if (this.itemFormAction === "create") {
        this.$emit("createItem", {
          name,
          description,
          introduction,
          categories,
          photoURLs,
          price,
          quantityOfStock,
          variations
        });
      } else {
        this.$emit("updateItem", {
          id: this.itemFormItemID,
          name,
          description,
          introduction,
          categories,
          photoURLs,
          price,
          quantityOfStock,
          variations
        });
      }
    },
    itemFormCancel() {
      this.itemFormShow = false;
    },
    alertDialogOk() {
      this.alertDialogShow = false;
      if (this.alertDialogFunc) this.alertDialogFunc(this.alertDialogData);
      this.alertDialogTitle = "";
      this.alertDialogText = "";
      this.alertDialogFunc = null;
      this.alertDialogData = null;
    },
    alertDialogCancel() {
      this.alertDialogShow = false;
      this.alertDialogTitle = "";
      this.alertDialogText = "";
      this.alertDialogFunc = null;
      this.alertDialogData = null;
    },
    toItemTelegramBot(itemID) {
      window.open(`https://t.me/${this.telegramBotID}?start=item_${itemID}`);
    },
    copyLink(itemID) {
      navigator.clipboard
        .writeText(`https://t.me/${this.telegramBotID}?start=item_${itemID}`)
        .then(() => {
          this.alertShow = true;
          this.alertDismissible = false;
          this.alertColor = "success";
          this.alertTitle = this.$t("__copy_successful");
          setTimeout(() => {
            this.alertShow = false;
            this.alertTitle = "";
          }, 5000);
        });
    },
    showQrcode(item) {
      this.qrcodeDialogTitle = item.data.name;
      this.qrcodeDialogLink = `https://t.me/${this.telegramBotID}?start=item_${item.id}`;
      this.qrcodeDialogShow = true;
    },
    closeQrcode() {
      this.qrcodeDialogTitle = "";
      this.qrcodeDialogShow = false;
    }
  }
};
</script>

<style>
.v-data-table-header th {
  text-align: center;
  white-space: nowrap;
}
</style>
