var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto"},[_c('v-toolbar',{attrs:{"color":"primary","dark":"","flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("__items")))]),_c('v-spacer'),_c('v-btn',{staticClass:"ml-2",attrs:{"outlined":""},on:{"click":_vm.toCreateItem}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"item.photo",fn:function(ref){
var item = ref.item;
return [(item.photo)?_c('img',{staticClass:"py-3",attrs:{"src":item.photo,"width":"100"}}):_c('p',{staticClass:"text-center"},[_vm._v("-")])]}},{key:"item.data.name",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"block":"","text":"","color":"primary"},on:{"click":function($event){return _vm.toItemTelegramBot(item.id)}}},[_vm._v(" "+_vm._s(item.data.name))]),_c('div',{staticClass:"text-center"},[(item.published)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.copyLink(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("__copy_item_link")))])]):_vm._e(),(item.published)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.showQrcode(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v("mdi-qrcode")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("__item_qrcode")))])]):_vm._e()],1)]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"ma-2",attrs:{"small":"","color":"darken-2"},on:{"click":function($event){return _vm.toUpdateItem(item)}}},[_vm._v("mdi-pencil")])]}},{key:"item.published",fn:function(ref){
var item = ref.item;
return [(item.published)?[_c('v-chip',{staticClass:"ma-1",attrs:{"label":"","small":"","color":"green white--text"}},[_vm._v(" "+_vm._s(_vm.$t("__itemlist_published_published"))+" ")]),_c('v-btn',{staticClass:"ma-1",attrs:{"x-small":""},on:{"click":function($event){return _vm.toDelistItem(item)}}},[_vm._v(_vm._s(_vm.$t("__itemlist_delist")))])]:[_c('v-chip',{staticClass:"ma-1",attrs:{"label":"","small":"","color":"red white--text"}},[_vm._v(" "+_vm._s(_vm.$t("__itemlist_published_delisted"))+" ")]),_c('v-btn',{staticClass:"ma-1",attrs:{"x-small":""},on:{"click":function($event){return _vm.toPublishItem(item)}}},[_vm._v(_vm._s(_vm.$t("__itemlist_publish")))])]]}},{key:"item.categories",fn:function(ref){
var item = ref.item;
return _vm._l((item.categories),function(category){return _c('v-chip',{key:category.id,staticClass:"ma-1",attrs:{"label":"","small":""}},[_vm._v(" "+_vm._s(category.data.name)+" ")])})}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v("$"+_vm._s(item.price))]}},{key:"item.quantityOfStock",fn:function(ref){
var item = ref.item;
return [(item.unlimitedSupply)?[_vm._v("-")]:(item.quantityOfStock > 0)?[_vm._v(_vm._s(item.quantityOfStock))]:[_c('v-chip',{attrs:{"label":"","small":"","color":"red white--text"}},[_vm._v(" "+_vm._s(_vm.$t("__itemlist_quantityofstock_soldout"))+" ")])]]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"ma-2",attrs:{"small":"","color":"red darken-2"},on:{"click":function($event){return _vm.toDeleteItem(item)}}},[_vm._v("mdi-delete")])]}}],null,true)})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"800px"},model:{value:(_vm.itemFormShow),callback:function ($$v) {_vm.itemFormShow=$$v},expression:"itemFormShow"}},[_c('ItemForm',{attrs:{"action":_vm.itemFormAction,"itemData":_vm.itemFormItemData},on:{"ok":_vm.itemFormOk,"cancel":_vm.itemFormCancel}})],1),_c('AlertDialog',{attrs:{"show":_vm.alertDialogShow,"title":_vm.alertDialogTitle,"text":_vm.alertDialogText,"oktext":_vm.$t('__confirm'),"canceltext":_vm.$t('__cancel')},on:{"ok":_vm.alertDialogOk,"cancel":_vm.alertDialogCancel}}),_c('alert',{attrs:{"title":_vm.alertTitle,"color-type":_vm.alertColor,"alert-open":_vm.alertShow,"dismissible":_vm.alertDismissible}}),_c('v-dialog',{attrs:{"max-width":"400px","persistent":""},model:{value:(_vm.qrcodeDialogShow),callback:function ($$v) {_vm.qrcodeDialogShow=$$v},expression:"qrcodeDialogShow"}},[_c('QRcodeDialog',{attrs:{"type":'item',"title":_vm.qrcodeDialogTitle,"telegramBotID":_vm.telegramBotID,"link":_vm.qrcodeDialogLink},on:{"close":_vm.closeQrcode}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }