<template>
  <v-card>
    <v-card-title>
      <span class="headline">{{ formtitletext }}</span>
    </v-card-title>

    <v-card-text>
      <v-form ref="form" v-model="isValid">
        <v-container>
          <v-row align="center">
            <v-col cols="12">
              <v-checkbox
                :label="$t('__store_payment_method_enable')"
                v-model="form.enabled"
              />
            </v-col>
            <v-col cols="12">
              <v-select
                outlined
                dense
                :label="$t('__store_payment_method_type') + ' *'"
                :items="paymentOptions"
                :disabled="action === 'edit'"
                item-text="ch"
                item-value="en"
                v-model="form.type"
                @change="form.name = ''"
              >
                <template slot="no-data">
                  <div class="pa-2">{{ $t("__storeplan_suspend_text") }}</div>
                </template>
              </v-select>
            </v-col>
            <v-col
              cols="12"
              v-if="form.type === 'tappay' || form.type === 'ecpay'"
            >
              <v-select
                outlined
                dense
                :label="$t('__store_payment_method_method') + ' *'"
                :items="methodOptions"
                :disabled="action === 'edit'"
                :rules="[requiredRule]"
                item-text="ch"
                item-value="en"
                v-model="form.method"
              >
              </v-select>
            </v-col>
            <v-col cols="12">
              <v-text-field
                outlined
                dense
                autofocus
                :label="$t('__store_payment_method_name') + ' *'"
                :rules="[requiredRule]"
                v-model="form.name"
              >
              </v-text-field>
            </v-col>
            <!-- transfer -->
            <template v-if="form.type === 'transfer'">
              <v-col cols="4">
                <v-text-field
                  outlined
                  dense
                  type="number"
                  :label="
                    $t('__store_payment_method_transfer_bank_code') + ' *'
                  "
                  :rules="[requiredRule, bankCodeRule]"
                  v-model="form.bankCode"
                >
                </v-text-field>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  outlined
                  dense
                  :label="$t('__store_payment_method_transfer_account') + ' *'"
                  :rules="[requiredRule, numberRule]"
                  v-model="form.account"
                >
                </v-text-field>
              </v-col>
            </template>
            <!-- tappay -->
            <template v-if="form.type === 'tappay'">
              <v-col cols="12">
                <v-text-field
                  outlined
                  dense
                  type="number"
                  :label="$t('__store_payment_method_tappay_app_id') + ' *'"
                  :rules="[requiredRule]"
                  v-model.number="form.appID"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  dense
                  :label="$t('__store_payment_method_tappay_app_key') + ' *'"
                  :rules="[requiredRule]"
                  v-model="form.appKey"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  dense
                  :label="
                    $t('__store_payment_method_tappay_partner_key') + ' *'
                  "
                  :rules="[requiredRule]"
                  v-model="form.partnerKey"
                >
                </v-text-field>
              </v-col>

              <!-- google pay -->
              <v-col cols="12" v-if="form.method === 'google-pay'">
                <v-text-field
                  outlined
                  dense
                  :label="
                    $t('__store_payment_method_tappay_googlepay_merchant_id') +
                      ' *'
                  "
                  :rules="[requiredRule]"
                  v-model="form.googleMerchantID"
                >
                </v-text-field>
              </v-col>
              <!-- apple pay -->
              <v-col cols="12" v-if="form.method === 'apple-pay'">
                <v-text-field
                  outlined
                  dense
                  :label="
                    $t(
                      '__store_payment_method_tappay_applepay_apple_merchant_identifier'
                    ) + ' *'
                  "
                  :rules="[requiredRule]"
                  v-model="form.appleMerchantIdentifier"
                >
                </v-text-field>
              </v-col>
            </template>
            <!-- Merchant ID -->
            <v-col
              cols="12"
              v-if="form.type === 'tappay' || form.type === 'ecpay'"
            >
              <v-text-field
                outlined
                dense
                :label="$t('__store_payment_method_tappay_merchant_id') + ' *'"
                :rules="[requiredRule]"
                v-model="form.merchantID"
              >
              </v-text-field>
            </v-col>
            <!-- Hash Key & Hash IV -->
            <template v-if="form.type === 'ecpay'">
              <v-col cols="12">
                <v-text-field
                  outlined
                  dense
                  :label="$t('__store_payment_method_ecpay_hash_key') + ' *'"
                  :rules="[requiredRule]"
                  v-model="form.hashKey"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  dense
                  :label="$t('__store_payment_method_ecpay_hash_iv') + ' *'"
                  :rules="[requiredRule]"
                  v-model="form.hashIV"
                >
                </v-text-field>
              </v-col>
            </template>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="cancel">{{
        formcanceltext
      }}</v-btn>
      <v-btn color="blue darken-1" text @click="ok" :disabled="!isValid">{{
        formoktext
      }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    action: {
      type: String
    },
    paymentMethodStoreData: {
      type: Object
    }
  },
  computed: {
    ...mapGetters({
      atm: "store/paymentATM",
      cash: "store/paymentCash",
      cashOnDelivery: "store/paymemtCashOnDelivery",
      tappay: "store/paymentTappay",
      ecpay: "store/paymentEcpay"
    }),
    methodOptions() {
      let options = [];
      if (this.form.type === "tappay") {
        options = [
          {
            ch: this.$t("__store_payment_method_tappay-direct-pay"),
            en: "direct-pay"
          },
          {
            ch: this.$t("__store_payment_method_tappay-google-pay"),
            en: "google-pay"
          },
          {
            ch: this.$t("__store_payment_method_tappay-apple-pay"),
            en: "apple-pay"
          },
          {
            ch: this.$t("__store_payment_method_tappay-samsung-pay"),
            en: "samsung-pay"
          },
          {
            ch: this.$t("__store_payment_method_tappay-line-pay"),
            en: "line-pay"
          },
          {
            ch: this.$t("__store_payment_method_tappay-jko-pay"),
            en: "jko-pay"
          },
          {
            ch: this.$t("__store_payment_method_tappay-easy-wallet"),
            en: "easy-wallet"
          }
        ];
      } else if (this.form.type === "ecpay") {
        options = [
          {
            ch: this.$t("__store_payment_method_ecpay-credit"),
            en: "credit"
          },
          {
            ch: this.$t("__store_payment_method_ecpay-web-atm"),
            en: "web-atm"
          },
          {
            ch: this.$t("__store_payment_method_ecpay-atm"),
            en: "atm"
          },
          {
            ch: this.$t("__store_payment_method_ecpay-cvs"),
            en: "cvs"
          },
          {
            ch: this.$t("__store_payment_method_ecpay-barcode"),
            en: "barcode"
          }
        ];
      }
      return options;
    },
    paymentOptions() {
      let options = [];
      if (this.cash) {
        options.push({
          en: "cash",
          ch: this.$t("__store_payment_method_cash")
        });
      }
      if (this.atm) {
        options.push({
          en: "transfer",
          ch: this.$t("__store_payment_method_transfer")
        });
      }
      if (this.cashOnDelivery) {
        options.push({
          en: "cash-on-delivery",
          ch: this.$t("__store_payment_method_cash-on-delivery")
        });
      }
      if (this.ecpay) {
        options.push({
          en: "ecpay",
          ch: this.$t("__store_payment_method_ecpay")
        });
      }
      if (this.tappay) {
        options.push({
          en: "tappay",
          ch: this.$t("__store_payment_method_tappay")
        });
      }
      return options;
    }
  },
  data() {
    return {
      form: {
        enabled: false,
        type: "cash",
        name: "",
        bankCode: "",
        account: "",
        method: "", //tappay, ecpay
        appID: 0, // tappay
        appKey: "", // tappay
        partnerKey: "", // tappay
        merchantID: "", //tappay,ecpay
        googleMerchantID: "", // tappay(google-pay)
        appleMerchantIdentifier: "", // tappay(apple-pay)
        hashKey: "", //ecpay
        hashIV: "" //ecpay
      },
      isValid: true,
      requiredRule: v => !!v || this.$t("__required"),
      numberRule: v => {
        const pattern = /^[0-9]+$/;
        return pattern.test(v) || this.$t("__invalid");
      },
      bankCodeRule: v => v.length == 3 || this.$t("__invalid"),
      formtitletext: "",
      formoktext: "",
      formcanceltext: ""
    };
  },
  watch: {
    action: {
      immediate: true,
      handler(newValue) {
        if (newValue === "create") {
          this.formtitletext = this.$t("__add_store_payment_method");
          this.formoktext = this.$t("__create");
          this.formcanceltext = this.$t("__cancel");
        } else {
          this.formtitletext = this.$t("__edit_store_payment_method");
          this.formoktext = this.$t("__confirm");
          this.formcanceltext = this.$t("__cancel");
        }
      }
    },
    "form.method": {
      handler(newValue) {
        if (this.action === "create") {
          switch (this.form.type) {
            case "tappay":
              this.form.name = this.$t(
                `__store_payment_method_tappay-${newValue}`
              );
              break;
            case "ecpay":
              this.form.name = this.$t(
                `__store_payment_method_ecpay-${newValue}`
              );
              break;
            default:
              break;
          }
        }
      }
    },
    "form.type": {
      immediate: true,
      handler(val) {
        this.form.name = this.$t(`__store_payment_method_${val}`);
      }
    },
    paymentMethodStoreData: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) {
          this.form = {
            enabled: newValue.enabled,
            type: newValue.type,
            name: newValue.name
          };
          if (newValue.data) {
            this.form.method = newValue.data["method"]
              ? newValue.data["method"]
              : "";
            this.form.bankCode = newValue.data["bank_code"]
              ? newValue.data["bank_code"]
              : "";
            this.form.account = newValue.data["account"]
              ? newValue.data["account"]
              : "";
            this.form.appID = newValue.data["app_id"]
              ? newValue.data["app_id"]
              : 0;
            this.form.appKey = newValue.data["app_key"]
              ? newValue.data["app_key"]
              : "";
            this.form.partnerKey = newValue.data["partner_key"]
              ? newValue.data["partner_key"]
              : "";
            this.form.merchantID = newValue.data["merchant_id"]
              ? newValue.data["merchant_id"]
              : "";
            this.form.googleMerchantID = newValue.data["google_merchant_id"]
              ? newValue.data["google_merchant_id"]
              : "";
            this.form.appleMerchantIdentifier = newValue.data[
              "apple_merchant_identifier"
            ]
              ? newValue.data["apple_merchant_identifier"]
              : "";
            this.form.hashKey = newValue.data["hash_key"]
              ? newValue.data["hash_key"]
              : "";
            this.form.hashIV = newValue.data["hash_iv"]
              ? newValue.data["hash_iv"]
              : "";
          }
        } else {
          this.form = {
            enabled: false,
            type: "cash",
            bankCode: "",
            account: "",
            appID: 0,
            appKey: "",
            partnerKey: "",
            merchantID: "",
            googleMerchantID: "",
            appleMerchantIdentifier: ""
          };
        }
      }
    }
  },
  methods: {
    formInit() {
      this.form = {
        enabled: false,
        type: "cash",
        name: "",
        bankCode: "",
        account: "",
        method: "",
        appID: 0,
        appKey: "",
        partnerKey: "",
        merchantID: "",
        googleMerchantID: "",
        appleMerchantIdentifier: "",
        hashKey: "",
        hashIV: ""
      };
    },
    ok() {
      this.$emit("ok", this.form);
      this.formInit();
    },
    cancel() {
      this.$emit("cancel");
      this.formInit();
    }
  }
};
</script>
