<template>
  <v-card>
    <v-card-title>
      <span class="headline">{{ formtitle }}</span>
    </v-card-title>

    <v-card-text>
      <v-form ref="form" v-model="isValid">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                dense
                outlined
                v-model="form.name"
                :label="$t('__item_form_name') + ' *'"
                required
                :rules="[requiredRule]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                dense
                outlined
                v-model="form.description"
                :label="$t('__item_form_description') + ' *'"
                required
                :rules="[requiredRule]"
                counter
                maxlength="100"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-combobox
                dense
                outlined
                :label="$t('__item_form_categories')"
                v-model="form.categories"
                :items="storeCategories"
                :item-value="item => item.data.name"
                :filter="filter"
                :hide-no-data="!categorySearch"
                :search-input.sync="categorySearch"
                multiple
                hide-selected
                small-chips
                :hint="$t('__item_form_categories_hint')"
                persistent-hint
              >
                <!-- when no search result -->
                <template v-slot:no-data>
                  <v-list-item>
                    <span class="subheading">{{
                      $t("__category_create")
                    }}</span>
                    <v-chip label small>{{ categorySearch }}</v-chip>
                  </v-list-item>
                </template>
                <!-- seletced item -->
                <template v-slot:selection="{ attrs, item, parent, selected }">
                  <v-chip
                    v-if="item === Object(item)"
                    v-bind="attrs"
                    :input-value="selected"
                    label
                    small
                  >
                    <span class="pr-2">{{ item.data.name }}</span>
                    <v-icon small @click="parent.selectItem(item)"
                      >mdi-close</v-icon
                    >
                  </v-chip>
                </template>
                <!-- menu item -->
                <template v-slot:item="{ item }">
                  <v-chip label small>{{ item.data.name }}</v-chip>
                </template>
              </v-combobox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="text-h7 mb-2">
                {{ $t("__item_form_photo") }}
              </div>
              <v-input
                :messages="photoUrlCountMsg"
                :error="photoUrlCount > 10"
                class="mb-4"
              >
                <div class="outline-div">
                  <!-- photoUrl item -->
                  <template v-for="(_, i) of form.photos">
                    <div :key="`item-${i}`" class="d-flex">
                      <photo-url-input
                        class="ma-2 mb-0 flex-grow-1"
                        dense
                        outlined
                        v-model="form.photos[i]"
                        :label="$t('__item_form_photo_url')"
                      />
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn class="ma-2" icon v-bind="attrs" v-on="on">
                            <v-icon
                              @click="deletePhotoUrl(i)"
                              large
                              color="grey lighten-1"
                              >mdi-minus-circle-outline</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>{{ $t("__delete") }}</span>
                      </v-tooltip>
                    </div>
                    <v-divider :key="`item-${i}-divider`" />
                  </template>
                  <!-- add button -->
                  <div class="d-flex justify-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="addPhotoUrl"
                          class="ma-2"
                          icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon large color="grey lighten-1"
                            >mdi-plus-circle-outline</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>{{ $t("__new") }}</span>
                    </v-tooltip>
                  </div>
                </div>
              </v-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="introduction">
                <p>{{ $t("__item_form_introduction") }}</p>
                <HTMLEditor v-model="form.introduction" />
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                dense
                outlined
                v-model="form.price"
                :label="$t('__item_form_price') + ' *'"
                type="number"
                min="0"
                required
                :rules="[requiredRule, numberRule, greater0Rule]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-checkbox
                hide-details
                v-model="form.unlimitedSupply"
                :label="$t('__item_form_unlimitedsupply')"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                dense
                outlined
                v-model="form.quantityOfStock"
                :label="$t('__item_form_quantityofstock') + ' *'"
                type="number"
                min="0"
                required
                :rules="[requiredRule, numberRule]"
                :disabled="form.unlimitedSupply"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="cancel">{{ formcancel }}</v-btn>
      <v-btn color="blue darken-1" text @click="ok" :disabled="!isValid">{{
        formok
      }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import HTMLEditor from "@/components/HTMLEditor";

export default {
  components: {
    HTMLEditor
  },
  computed: {
    ...mapGetters({
      storeCategories: "store/categories"
    }),
    photoUrlCount() {
      if (this.form.photos.length !== 0) {
        return this.form.photos.length;
      }
      return 0;
    },
    photoUrlCountMsg() {
      return `${this.photoUrlCount}/10`;
    }
  },
  props: {
    action: {
      type: String,
      required: true
    },
    itemData: {
      required: true
    }
  },
  data() {
    return {
      formtitle: "",
      formok: "",
      formcancel: "",
      form: {
        name: "",
        description: "",
        introduction: "",
        photos: [],
        categories: [],
        price: "0",
        unlimitedSupply: false,
        quantityOfStock: "0"
      },
      isValid: true,
      requiredRule: v => !!v || this.$t("__required"),
      numberRule: v =>
        (!isNaN(v) && parseFloat(v) >= 0) || this.$t("__number_invalid"),
      greater0Rule: v =>
        (!isNaN(v) && parseFloat(v) > 0) || this.$t("__greater_0_invalid"),
      categorySearch: null
    };
  },
  watch: {
    action: {
      immediate: true,
      handler(newValue) {
        if (newValue === "create") {
          this.formtitle = this.$t("__createitem");
          this.formok = this.$t("__create");
          this.formcancel = this.$t("__cancel");
        } else {
          this.formtitle = this.$t("__edititem");
          this.formok = this.$t("__confirm");
          this.formcancel = this.$t("__cancel");
        }
      }
    },
    itemData: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (this.$refs.form) this.$refs.form.resetValidation();
        this.form.name = "";
        this.form.description = "";
        this.form.introduction = "";
        this.form.photos = [];
        this.form.categories = [];
        this.form.price = "0";
        this.form.unlimitedSupply = false;
        this.form.quantityOfStock = "0";
        if (newValue) {
          if (newValue["name"]) this.form.name = newValue["name"];
          if (newValue["description"])
            this.form.description = newValue["description"];
          if (newValue["introduction"])
            this.form.introduction = newValue["introduction"];
          if (newValue["photo_urls"] && newValue.photo_urls.length > 0)
            this.form.photos = newValue["photo_urls"];
          if (newValue["price"]) this.form.price = newValue["price"].toString();
          if (newValue["unlimited_supply"])
            this.form.unlimitedSupply = newValue["unlimited_supply"];
          if (newValue["quantity_of_stock"])
            this.form.quantityOfStock = newValue[
              "quantity_of_stock"
            ].toString();
          if (
            newValue["category_ids"] &&
            Array.isArray(newValue["category_ids"])
          ) {
            newValue["category_ids"].forEach(categoryID => {
              const found = this.storeCategories.find(c => c.id == categoryID);
              if (found) {
                this.form.categories.push(found);
              }
            });
          }
        }
      }
    },
    "form.categories"(val, prev) {
      if (val.length === prev.length) return;
      this.form.categories = val.map(category => {
        if (typeof category === "string") {
          category = {
            id: "",
            data: {
              name: category
            }
          };
        }
        return category;
      });
    }
  },
  methods: {
    filter(item, queryText) {
      const hasValue = val => (val != null ? val : "");

      const text = item.data.name;
      const query = hasValue(queryText);

      return (
        text
          .toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1
      );
    },
    addPhotoUrl() {
      if (this.form.photos.length === 10) return;
      this.form.photos.splice(this.form.photos.length, 1, "");
    },
    deletePhotoUrl(index) {
      this.form.photos.splice(index, 1);
    },
    ok() {
      this.$emit("ok", {
        name: this.form.name,
        description: this.form.description,
        introduction: this.form.introduction,
        categories: this.form.categories,
        photoURLs: this.form.photos,
        price: parseFloat(this.form.price),
        quantityOfStock: this.form.unlimitedSupply
          ? -1
          : parseInt(this.form.quantityOfStock)
      });
      this.form = {
        name: "",
        description: "",
        introduction: "",
        photos: [],
        categories: [],
        price: "0",
        unlimitedSupply: false,
        quantityOfStock: "0"
      };
    },
    cancel() {
      this.$emit("cancel");
    }
  }
};
</script>

<style lang="scss">
.introduction {
  width: 100%;
  margin-bottom: 1em;
  p {
    margin: 0;
  }
}
.variation-card {
  margin-bottom: 1em;
}

.outline-div {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 4px;
}
</style>
