<template>
  <v-card>
    <v-card-title>
      <span class="headline">{{ formtitle }}</span>
    </v-card-title>

    <v-card-text>
      <v-form ref="form" v-model="isValid">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                outlined
                dense
                v-model="form.name"
                v-bind:label="$t('__category_name') + ' *'"
                required
                :rules="nameRules"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <photo-url-input
                class="flex-grow-1"
                dense
                outlined
                v-model="form.photoUrls[0]"
                :label="$t('__storemessagecronjob_data_url')"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="cancel">{{ formcancel }}</v-btn>
      <v-btn color="blue darken-1" text @click="ok" :disabled="!isValid">{{
        formok
      }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    action: {
      type: String,
      required: true
    },
    categoryData: {
      required: true
    }
  },
  data() {
    return {
      formtitle: "",
      formok: "",
      formcancel: "",
      form: {
        name: "",
        photoUrls: []
      },
      isValid: true,
      nameRules: [v => !!v || this.$t("__required")]
    };
  },
  watch: {
    action: {
      immediate: true,
      handler(newValue) {
        if (newValue === "create") {
          this.formtitle = this.$t("__createcategory");
          this.formok = this.$t("__create");
          this.formcancel = this.$t("__cancel");
        } else {
          this.formtitle = this.$t("__editcategory");
          this.formok = this.$t("__confirm");
          this.formcancel = this.$t("__cancel");
        }
      }
    },
    categoryData: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.form.name = newValue.name;
          if (newValue["photo_urls"]) {
            this.form.photoUrls = newValue["photo_urls"];
          } else {
            this.form.photoUrls = [];
          }
        } else {
          this.form = {
            name: "",
            photoUrls: []
          };
        }
      }
    }
  },
  methods: {
    ok() {
      this.$emit("ok", {
        name: this.form.name,
        photoURLs: this.form.photoUrls
      });
      this.form = {
        name: "",
        photoUrls: []
      };
    },
    cancel() {
      this.$emit("cancel");
    }
  }
};
</script>
