var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formtitle))])]),_c('v-card-text',[_c('v-form',{ref:"form",model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","label":_vm.$t('__item_form_name') + ' *',"required":"","rules":[_vm.requiredRule]},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"dense":"","outlined":"","label":_vm.$t('__item_form_description') + ' *',"required":"","rules":[_vm.requiredRule],"counter":"","maxlength":"100"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-combobox',{attrs:{"dense":"","outlined":"","label":_vm.$t('__item_form_categories'),"items":_vm.storeCategories,"item-value":function (item) { return item.data.name; },"filter":_vm.filter,"hide-no-data":!_vm.categorySearch,"search-input":_vm.categorySearch,"multiple":"","hide-selected":"","small-chips":"","hint":_vm.$t('__item_form_categories_hint'),"persistent-hint":""},on:{"update:searchInput":function($event){_vm.categorySearch=$event},"update:search-input":function($event){_vm.categorySearch=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('span',{staticClass:"subheading"},[_vm._v(_vm._s(_vm.$t("__category_create")))]),_c('v-chip',{attrs:{"label":"","small":""}},[_vm._v(_vm._s(_vm.categorySearch))])],1)]},proxy:true},{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [(item === Object(item))?_c('v-chip',_vm._b({attrs:{"input-value":selected,"label":"","small":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(_vm._s(item.data.name))]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v("mdi-close")])],1):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","small":""}},[_vm._v(_vm._s(item.data.name))])]}}]),model:{value:(_vm.form.categories),callback:function ($$v) {_vm.$set(_vm.form, "categories", $$v)},expression:"form.categories"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-h7 mb-2"},[_vm._v(" "+_vm._s(_vm.$t("__item_form_photo"))+" ")]),_c('v-input',{staticClass:"mb-4",attrs:{"messages":_vm.photoUrlCountMsg,"error":_vm.photoUrlCount > 10}},[_c('div',{staticClass:"outline-div"},[_vm._l((_vm.form.photos),function(_,i){return [_c('div',{key:("item-" + i),staticClass:"d-flex"},[_c('photo-url-input',{staticClass:"ma-2 mb-0 flex-grow-1",attrs:{"dense":"","outlined":"","label":_vm.$t('__item_form_photo_url')},model:{value:(_vm.form.photos[i]),callback:function ($$v) {_vm.$set(_vm.form.photos, i, $$v)},expression:"form.photos[i]"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"large":"","color":"grey lighten-1"},on:{"click":function($event){return _vm.deletePhotoUrl(i)}}},[_vm._v("mdi-minus-circle-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("__delete")))])])],1),_c('v-divider',{key:("item-" + i + "-divider")})]}),_c('div',{staticClass:"d-flex justify-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"icon":""},on:{"click":_vm.addPhotoUrl}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"large":"","color":"grey lighten-1"}},[_vm._v("mdi-plus-circle-outline")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("__new")))])])],1)],2)])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"introduction"},[_c('p',[_vm._v(_vm._s(_vm.$t("__item_form_introduction")))]),_c('HTMLEditor',{model:{value:(_vm.form.introduction),callback:function ($$v) {_vm.$set(_vm.form, "introduction", $$v)},expression:"form.introduction"}})],1)])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","label":_vm.$t('__item_form_price') + ' *',"type":"number","min":"0","required":"","rules":[_vm.requiredRule, _vm.numberRule, _vm.greater0Rule]},model:{value:(_vm.form.price),callback:function ($$v) {_vm.$set(_vm.form, "price", $$v)},expression:"form.price"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-checkbox',{attrs:{"hide-details":"","label":_vm.$t('__item_form_unlimitedsupply')},model:{value:(_vm.form.unlimitedSupply),callback:function ($$v) {_vm.$set(_vm.form, "unlimitedSupply", $$v)},expression:"form.unlimitedSupply"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","label":_vm.$t('__item_form_quantityofstock') + ' *',"type":"number","min":"0","required":"","rules":[_vm.requiredRule, _vm.numberRule],"disabled":_vm.form.unlimitedSupply},model:{value:(_vm.form.quantityOfStock),callback:function ($$v) {_vm.$set(_vm.form, "quantityOfStock", $$v)},expression:"form.quantityOfStock"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.cancel}},[_vm._v(_vm._s(_vm.formcancel))]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":!_vm.isValid},on:{"click":_vm.ok}},[_vm._v(_vm._s(_vm.formok))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }