<template>
  <v-card>
    <v-card-title>
      <span class="headline">{{ formtitletext }}</span>
    </v-card-title>

    <v-card-text>
      <v-form ref="form" v-model="isValid">
        <v-container>
          <v-row align="center">
            <v-col cols="12">
              <v-checkbox
                :label="$t('__store_shippingchannels_enabled')"
                v-model="form.enabled"
              />
            </v-col>
            <v-col cols="12">
              <v-select
                outlined
                dense
                :label="$t('__store_shippingchannels_type') + ' *'"
                :items="typeOptions"
                item-text="ch"
                item-value="en"
                v-model="form.type"
              >
                <template slot="no-data">
                  <div class="pa-2">{{ $t("__storeplan_suspend_text") }}</div>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12">
              <v-text-field
                autofocus
                outlined
                dense
                :label="$t('__store_shippingchannels_name') + ' *'"
                :rules="[requiredRule]"
                v-model="form.name"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                outlined
                dense
                :label="
                  $t('__store_shippingchannel_store_logistics_price') + ' *'
                "
                :rules="[numberRule]"
                v-model.number="form.price"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click.stop="cancel">{{
        formcanceltext
      }}</v-btn>
      <v-btn color="blue darken-1" text @click.stop="ok" :disabled="!isValid">{{
        formoktext
      }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    action: {
      type: String
    },
    sippingChannelData: {
      type: Object
    }
  },
  computed: {
    ...mapGetters({
      logistics: "store/shippingLogistics",
      pickup: "store/shippingPickup"
    }),
    typeOptions() {
      let options = [];
      if (this.logistics) {
        options.push({
          en: "logistics",
          ch: this.$t("__order_shipping_channel_logistics")
        });
      }
      if (this.pickup) {
        options.push({
          en: "pickup",
          ch: this.$t("__order_shipping_channel_pickup")
        });
      }
      return options;
    }
  },
  data() {
    return {
      form: {
        enabled: false,
        type: "pickup",
        name: "",
        price: ""
      },
      isValid: true,
      requiredRule: v => !!v || this.$t("__required"),
      numberRule: v => {
        const pattern = /^[0-9]+$/;
        return pattern.test(v) || this.$t("__invalid");
      },
      formtitletext: "",
      formoktext: "",
      formcanceltext: ""
    };
  },
  methods: {
    ok() {
      this.$emit("ok", {
        enabled: this.form.enabled,
        type: this.form.type,
        name: this.form.name,
        price: this.form.price
      });
      this.form = {
        enabled: false,
        type: "pickup",
        name: "",
        price: ""
      };
    },
    cancel() {
      this.$emit("cancel");
    }
  },
  watch: {
    action: {
      immediate: true,
      handler(newValue) {
        if (newValue === "create") {
          this.formtitletext = this.$t("__create_shippingchannels");
          this.formoktext = this.$t("__create");
          this.formcanceltext = this.$t("__cancel");
        } else {
          this.formtitletext = this.$t("__edit_shippingchannels");
          this.formoktext = this.$t("__confirm");
          this.formcanceltext = this.$t("__cancel");
        }
      }
    },
    sippingChannelData: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.form = { ...newValue };
        } else {
          this.form = {
            enabled: false,
            type: "pickup",
            name: "",
            price: ""
          };
        }
      }
    }
  }
};
</script>
