<template>
  <v-card class="mx-auto">
    <v-toolbar color="primary" dark flat>
      <v-toolbar-title>{{ $t("__store_shippingchannels") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn class="ml-2" outlined @click="toAddShippingChannelStore">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-data-table dense :headers="headers" :items="shippingChannelStoreDatas">
        <template v-slot:[`item.data.type`]="{ item }">
          {{ $t(`__store_shippingchannel_store_${item.data.type}`) }}
        </template>
        <template v-slot:[`item.data.enabled`]="{ item }">
          <v-icon v-if="item.data.enabled" color="green"
            >mdi-checkbox-marked-circle</v-icon
          >
          <v-icon v-else>mdi-close-circle</v-icon>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            class="ma-2"
            small
            color="darken-2"
            @click="toSetShippingChannelStore(item)"
            >mdi-pencil</v-icon
          >
          <v-icon
            class="ma-2"
            small
            color="red darken-2"
            @click="toDeleteShippingChannelStore(item)"
            >mdi-delete</v-icon
          >
        </template>
      </v-data-table>
    </v-card-text>

    <v-dialog
      v-model="shippingChannelStoreFormShow"
      persistent
      max-width="600px"
    >
      <ShippingChannelStoreForm
        :action="shippingChannelStoreAction"
        :sippingChannelData="shippingChannelStoreData"
        @ok="storeShippingChannelStoreFormOk"
        @cancel="storeShippingChannelStoreFormCancel"
      />
    </v-dialog>
    <AlertDialog
      :show="alertDialogShow"
      :title="alertDialogTitle"
      :text="alertDialogText"
      :oktext="$t('__confirm')"
      :canceltext="$t('__cancel')"
      @ok="alertDialogOk"
      @cancel="alertDialogCancel"
    />
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import ShippingChannelStoreForm from "@/components/Store/ShippingChannelStoreForm";
import AlertDialog from "@/components/AlertDialog";

export default {
  components: {
    ShippingChannelStoreForm,
    AlertDialog
  },
  computed: {
    ...mapGetters({
      storeData: "store/data",
      shippingChannelDatas: "store/shippingChannelStoreData"
    })
  },
  data() {
    return {
      headers: [
        {
          text: `${this.$t("__store_shippingchannels_type")}`,
          value: "data.type",
          align: "start"
        },
        {
          text: `${this.$t("__store_shippingchannels_name")}`,
          value: "data.name",
          align: "start"
        },
        {
          text: `${this.$t("__store_shippingchannel_store_logistics_price")}`,
          value: "data.price",
          align: "start"
        },
        {
          text: `${this.$t("__store_shippingchannels_enabled")}`,
          value: "data.enabled",
          align: "start"
        },
        {
          text: `${this.$t("__store_shippingchannels_actions")}`,
          value: "actions",
          align: "end",
          sortable: false
        }
      ],
      shippingChannelStoreDatas: [],
      shippingChannelStoreFormShow: false,
      shippingChannelStoreAction: "create",
      shippingChannelStoreID: "",
      shippingChannelStoreData: null,
      shippingChannelStoreDeleteAlertDialogShow: false,
      shippingChannelStoreDeleteAlertDialogID: "",
      alertDialogShow: false,
      alertDialogTitle: "",
      alertDialogText: ""
    };
  },
  methods: {
    toAddShippingChannelStore() {
      this.shippingChannelStoreAction = "create";
      this.shippingChannelStoreID = "";
      this.shippingChannelStoreData = null;
      this.shippingChannelStoreFormShow = true;
    },
    toSetShippingChannelStore(shippingChannel) {
      this.shippingChannelStoreAction = "edit";
      this.shippingChannelStoreID = shippingChannel.channelID;
      this.shippingChannelStoreData = shippingChannel.data;
      this.shippingChannelStoreFormShow = true;
    },
    toDeleteShippingChannelStore(item) {
      this.alertDialogTitle = this.$t("__delete_shippingchannels_title");
      this.alertDialogText = this.$t("__delete_shippingchannels_text").replace(
        "%1",
        item.data.name
      );
      this.shippingChannelStoreDeleteAlertDialogID = item.channelID;
      this.alertDialogShow = true;
    },
    storeShippingChannelStoreFormOk({ enabled, type, name, price }) {
      if (this.shippingChannelStoreAction === "create") {
        this.$emit("addShippingChannel", { enabled, type, name, price });
      } else {
        let setData = {
          enabled,
          type,
          name,
          price
        };
        this.$emit("setShippingChannel", {
          shippingChannelID: this.shippingChannelStoreID,
          setData
        });
      }
      this.shippingChannelStoreFormShow = false;
    },
    storeShippingChannelStoreFormCancel() {
      this.shippingChannelStoreFormShow = false;
    },
    alertDialogOk() {
      this.alertDialogShow = false;
      this.$emit("deleteShippingChannel", {
        shippingChannelID: this.shippingChannelStoreDeleteAlertDialogID
      });
      this.alertDialogTitle = "";
      this.alertDialogText = "";
      this.shippingChannelStoreDeleteAlertDialogID = "";
    },
    alertDialogCancel() {
      this.alertDialogShow = false;
      this.alertDialogTitle = "";
      this.alertDialogText = "";
      this.shippingChannelStoreDeleteAlertDialogID = "";
    }
  },
  watch: {
    shippingChannelDatas: {
      immediate: true,
      handler(val) {
        if (val) {
          this.shippingChannelStoreDatas = val;
        } else {
          this.shippingChannelStoreDatas = [];
        }
      }
    }
  }
};
</script>
