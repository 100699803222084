<template>
  <v-card>
    <v-card-title>
      {{ title }}
      <v-spacer />
      <v-btn @click="printQRcode" outlined color="primary">
        <v-icon>mdi-printer</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text
      class="d-flex justify-center"
      id="printItem"
      v-if="type === 'item'"
    >
      <span class="d-none d-print-block">
        {{ title }}
      </span>
      <qrcode-vue
        :value="link"
        size="150"
        renderAs="svg"
        class="d-none d-print-block"
      />
      <qrcode-vue :value="link" size="150" renderAs="canvas" />
    </v-card-text>
    <v-card-text
      class="d-flex justify-center"
      id="printCategory"
      v-if="type === 'category'"
    >
      <span class="d-none d-print-block">
        {{ title }}
      </span>
      <qrcode-vue
        :value="link"
        size="150"
        renderAs="svg"
        class="d-none d-print-block"
      />
      <qrcode-vue :value="link" size="150" renderAs="canvas" />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn text color="primary" @click="close">{{ $t("__close") }}</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import QrcodeVue from "qrcode.vue";

export default {
  name: "QRcodeDialog",
  components: {
    QrcodeVue
  },
  props: {
    title: {
      type: String
    },
    telegramBotID: {
      type: String
    },
    link: {
      type: String
    },
    type: {
      type: String
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    printQRcode() {
      if (this.type === "item") {
        this.$htmlToPaper("printItem");
      } else {
        this.$htmlToPaper("printCategory");
      }
    }
  }
};
</script>
