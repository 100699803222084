<template>
  <v-card>
    <v-card-title>
      <span>{{ $t("__store_autoreplyordertime") }}</span>
    </v-card-title>

    <v-card-text>
      <div class="text-h6">
        {{ $t("__store_autoreplyordertime_timezoneoffset") }}
      </div>
      <div class="subtitle-2 mb-2">
        {{ $t("__store_autoreplyordertime_timezoneoffset_hint") }}
      </div>
      <v-select
        v-model="timeZoneOffset"
        :items="timeZoneOffsets"
        outlined
        dense
      />
      <div class="text-h6 mb-2">
        {{ $t("__store_autoreplyordertime_week") }}
      </div>
      <div class="d-flex mb-2">
        <v-sheet width="20px" height="20px" color="green" />
        <div class="ml-2">{{ $t("__store_autoreplyordertime_accept") }}</div>
        <v-sheet class="ml-6" width="20px" height="20px" color="red" />
        <div class="ml-2">{{ $t("__store_autoreplyordertime_reject") }}</div>
      </div>
      <div class="subtitle-2 primary--text">
        {{ $t("__store_autoreplyordertime_setting_hint") }}
      </div>
      <v-form ref="form" v-model="isValid">
        <table class="time-table" border="1">
          <tr>
            <td />
            <template v-for="d in 7">
              <th class="time-table__header-cell" :key="d">
                <div>
                  {{ $t(`__store_autoreplyordertime_weekday_${d - 1}`) }}
                </div>
              </th>
            </template>
          </tr>
          <tr v-for="(_, t) in intervalsStatus[0]" :key="t">
            <th
              :class="{
                'time-table__header-cell': true,
                'time-table__time-cell': true,
                'time-table__mid-time-cell':
                  t === Math.floor(numOfTimeInterval / 2) - 1
              }"
            >
              <div>{{ intervalIndexToString(t) }}</div>
            </th>
            <template v-for="(weekdayIntervalsStatus, d) in intervalsStatus">
              <td
                :class="{
                  'time-table__status-cell': true,
                  'time-table__mid-time-cell':
                    t === Math.floor(numOfTimeInterval / 2) - 1
                }"
                :key="`interval-${d}-${t}`"
                @mousedown="intervalMouseDown(d, t, $event)"
                @mouseenter="intervalMouseEnter(d, t, $event)"
              >
                <div>
                  <v-sheet
                    width="100%"
                    height="100%"
                    :color="
                      weekdayIntervalsStatus[t] === 1
                        ? 'green'
                        : weekdayIntervalsStatus[t] === 2
                        ? 'red'
                        : 'white'
                    "
                  />
                </div>
              </td>
            </template>
          </tr>
        </table>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="cancel">{{
        $t("__cancel")
      }}</v-btn>
      <v-btn color="blue darken-1" text @click="ok" :disabled="!isValid">{{
        $t("__confirm")
      }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    data: {
      required: true
    }
  },
  data() {
    return {
      timeZoneOffset: "+00:00",
      timeZoneOffsets: [
        "−12:00",
        "−11:00",
        "−10:00",
        "−09:30",
        "−09:00",
        "−08:00",
        "−07:00",
        "−06:00",
        "−05:00",
        "−04:00",
        "−03:30",
        "−03:00",
        "−02:00",
        "−01:00",
        "+00:00",
        "+01:00",
        "+02:00",
        "+03:00",
        "+03:30",
        "+04:00",
        "+04:30",
        "+05:00",
        "+05:30",
        "+05:45",
        "+06:00",
        "+06:30",
        "+07:00",
        "+08:00",
        "+08:45",
        "+09:00",
        "+09:30",
        "+10:00",
        "+10:30",
        "+11:00",
        "+12:00",
        "+12:45",
        "+13:00"
      ],
      intervalMinute: 30,
      intervalsStatus: [],
      intervalsStatusBackup: [],
      mouseDownIntervalIndex: [0, 0],
      mouseDownIntervalStatus: 0,
      form: {},
      isValid: true
    };
  },
  computed: {
    numOfTimeInterval() {
      return (24 * 60) / this.intervalMinute;
    }
  },
  methods: {
    intervalIndexToString(index) {
      return `${this.intervalIndexStartTimeString(
        index
      )} ~ ${this.intervalIndexEndTimeString(index)}`;
    },
    intervalIndexStartTimeMinutes(index) {
      return index * this.intervalMinute;
    },
    intervalIndexStartTimeString(index) {
      return this.minutesToString(this.intervalIndexStartTimeMinutes(index));
    },
    intervalIndexEndTimeMinutes(index) {
      return (index + 1) * this.intervalMinute - 1;
    },
    intervalIndexEndTimeString(index) {
      return this.minutesToString(this.intervalIndexEndTimeMinutes(index));
    },
    minutesToString(minutes) {
      const hour = Math.floor(minutes / 60)
        .toString()
        .padStart(2, "0");
      const minute = (minutes % 60).toString().padStart(2, "0");
      return `${hour}:${minute}`;
    },
    stringToMinutes(str) {
      const subStrs = str.split(":");
      if (subStrs.length !== 2) return -1;
      if (isNaN(subStrs[0]) || isNaN(subStrs[1])) return -1;
      const hour = parseInt(subStrs[0]);
      const minute = parseInt(subStrs[1]);
      return hour * 60 + minute;
    },
    initIntervalsStatus() {
      this.intervalsStatus = [];
      this.intervalsStatusBackup = [];
      for (let d = 0; d < 7; d++) {
        this.intervalsStatus.push(Array(this.numOfTimeInterval).fill(0));
        this.intervalsStatusBackup.push(Array(this.numOfTimeInterval).fill(0));
      }
    },
    backupIntervalsStatus() {
      for (let d = 0; d < 7; d++) {
        for (let t = 0; t < this.numOfTimeInterval; t++) {
          this.intervalsStatusBackup[d][t] = this.intervalsStatus[d][t];
        }
      }
    },
    restoreIntervalsStatus() {
      for (let d = 0; d < 7; d++) {
        for (let t = 0; t < this.numOfTimeInterval; t++) {
          this.intervalsStatus[d][t] = this.intervalsStatusBackup[d][t];
        }
      }
    },
    intervalMouseDown(d, t, e) {
      if (e.buttons === 1) {
        this.backupIntervalsStatus();
        this.mouseDownIntervalIndex = [d, t];
        this.mouseDownIntervalStatus = (this.intervalsStatus[d][t] + 1) % 3;
        this.intervalsStatus[d][t] = this.mouseDownIntervalStatus;
        this.$forceUpdate();
      }
    },
    intervalMouseEnter(d, t, e) {
      if (e.buttons === 1) {
        this.restoreIntervalsStatus();
        const minD = Math.min(d, this.mouseDownIntervalIndex[0]);
        const maxD = Math.max(d, this.mouseDownIntervalIndex[0]);
        const minT = Math.min(t, this.mouseDownIntervalIndex[1]);
        const maxT = Math.max(t, this.mouseDownIntervalIndex[1]);
        for (let iterD = minD; iterD <= maxD; iterD++) {
          for (let iterT = minT; iterT <= maxT; iterT++) {
            this.intervalsStatus[iterD][iterT] = this.mouseDownIntervalStatus;
          }
        }
        this.$forceUpdate();
      }
    },
    ok() {
      const weekdayTimeIntervals = {};
      for (let d = 0; d < 7; d++) {
        const timeIntervals = [];
        for (let t = 0; t < this.numOfTimeInterval; t++) {
          const status = this.intervalsStatus[d][t];
          if (status === 1 || status === 2) {
            const startTimeMinutes = this.intervalIndexStartTimeMinutes(t);
            const endTimeMinutes = this.intervalIndexEndTimeMinutes(t);
            const action = status === 1 ? "accept" : "reject";
            if (
              timeIntervals.length > 0 &&
              this.minutesToString(startTimeMinutes - 1) ===
                timeIntervals[timeIntervals.length - 1]["end_time"] &&
              action === timeIntervals[timeIntervals.length - 1]["action"]
            ) {
              timeIntervals[timeIntervals.length - 1][
                "end_time"
              ] = this.minutesToString(endTimeMinutes);
            } else {
              timeIntervals.push({
                start_time: this.minutesToString(startTimeMinutes),
                end_time: this.minutesToString(endTimeMinutes),
                action: action
              });
            }
          }
        }
        weekdayTimeIntervals[d.toString()] = timeIntervals;
      }
      this.$emit("ok", {
        mode: "week",
        timeZoneOffset: this.timeZoneOffset,
        weekdayTimeIntervals
      });
    },
    cancel() {
      this.$emit("cancel");
    }
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler(val) {
        if (this.intervalsStatus.length === 0) {
          let offset = new Date().getTimezoneOffset();
          let offsetHour = (Math.abs(offset) / 60).toString().padStart(2, "0");
          offsetHour = (offset > 0 ? "-" : "+") + offsetHour;
          this.timeZoneOffset = offsetHour + ":00";
          this.initIntervalsStatus();
        }
        if (val) this.timeZoneOffset = val["time_zone_offset"];
        for (let d = 0; d < 7; d++) {
          const dataIntervalsMinutesAndStatus = [];
          if (val) {
            const dataIntervals = val["weekday_time_intervals"][d.toString()];
            if (dataIntervals) {
              for (const dataInterval of val["weekday_time_intervals"][
                d.toString()
              ]) {
                const startTimeMinutes = this.stringToMinutes(
                  dataInterval["start_time"]
                );
                const endTimeMinutes = this.stringToMinutes(
                  dataInterval["end_time"]
                );
                const action = dataInterval["action"];
                if (
                  startTimeMinutes != -1 &&
                  endTimeMinutes != -1 &&
                  (action === "accept" || action === "reject")
                ) {
                  const status = action === "accept" ? 1 : 2;
                  dataIntervalsMinutesAndStatus.push({
                    startTimeMinutes,
                    endTimeMinutes,
                    status
                  });
                }
              }
            }
          }
          for (let t = 0; t < this.numOfTimeInterval; t++) {
            const startTimeMinutes = this.intervalIndexStartTimeMinutes(t);
            const endTimeMinutes = this.intervalIndexEndTimeMinutes(t);
            let status = 0;
            for (const mAndS of dataIntervalsMinutesAndStatus) {
              if (
                (startTimeMinutes >= mAndS.startTimeMinutes &&
                  startTimeMinutes <= mAndS.endTimeMinutes) ||
                (endTimeMinutes >= mAndS.startTimeMinutes &&
                  endTimeMinutes <= mAndS.endTimeMinutes)
              ) {
                status = mAndS.status;
              }
            }
            this.intervalsStatus[d][t] = status;
          }
        }
        this.$forceUpdate();
      }
    }
  }
};
</script>

<style lang="scss">
.time-table {
  width: 100%;
  border-collapse: collapse;

  &__header-cell {
    font-size: 0.8em;
    padding: 0 1em;

    div {
      height: 18px;
    }
  }
  &__time-cell {
    width: 1%;
    white-space: nowrap;
  }
  &__status-cell {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    div {
      height: 18px;
    }
  }
  &__mid-time-cell {
    border-bottom: 2px solid black;
  }
}
</style>
