var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto"},[_c('v-toolbar',{attrs:{"color":"primary","dark":"","flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("__store_categories")))]),_c('v-spacer'),_c('v-btn',{staticClass:"ml-2",attrs:{"outlined":""},on:{"click":_vm.toAddCategory}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.categories,"show-select":""},scopedSlots:_vm._u([{key:"item.data.name",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){return _vm.toItemTelegramBot(item.id)}}},[_vm._v(" "+_vm._s(item.data.name))]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.copyLink(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("__copy_category_link")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.showQrcode(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v("mdi-qrcode")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("__category_qrcode")))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"ma-2",attrs:{"small":"","color":"darken-2"},on:{"click":function($event){return _vm.toUpdateCategory(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{staticClass:"ma-2",attrs:{"small":"","color":"red darken-2"},on:{"click":function($event){return _vm.toDeleteCategory(item)}}},[_vm._v("mdi-delete")])]}}],null,true),model:{value:(_vm.selectedCategories),callback:function ($$v) {_vm.selectedCategories=$$v},expression:"selectedCategories"}})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.categoryFormShow),callback:function ($$v) {_vm.categoryFormShow=$$v},expression:"categoryFormShow"}},[_c('CategoryForm',{attrs:{"action":_vm.categoryFormAction,"categoryData":_vm.categoryFormCategoryData},on:{"ok":_vm.storeCategoryDataDialogOk,"cancel":_vm.storeCategoryDataDialogCancel}})],1),_c('AlertDialog',{attrs:{"show":_vm.categoryDeleteAlertDialogShow,"title":_vm.$t('__deletecategory_title'),"text":_vm.$t('__deletecategory_text'),"oktext":_vm.$t('__confirm'),"canceltext":_vm.$t('__cancel')},on:{"ok":_vm.storeCategoryDeleteAlertDialogOk,"cancel":_vm.storeCategoryDeleteAlertDialogCancel}}),_c('alert',{attrs:{"title":_vm.alertTitle,"color-type":_vm.alertColor,"alert-open":_vm.alertShow,"dismissible":_vm.alertDismissible}}),_c('v-dialog',{attrs:{"max-width":"400px","persistent":""},model:{value:(_vm.qrcodeDialogShow),callback:function ($$v) {_vm.qrcodeDialogShow=$$v},expression:"qrcodeDialogShow"}},[_c('QRcodeDialog',{attrs:{"type":'category',"title":_vm.qrcodeDialogTitle,"link":_vm.qrcodeDialogLink,"telegramBotID":_vm.telegramBotID},on:{"close":_vm.closeQrcode}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }