var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('span',[_vm._v(_vm._s(_vm.$t("__store_autoreplyordertime")))])]),_c('v-card-text',[_c('div',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.$t("__store_autoreplyordertime_timezoneoffset"))+" ")]),_c('div',{staticClass:"subtitle-2 mb-2"},[_vm._v(" "+_vm._s(_vm.$t("__store_autoreplyordertime_timezoneoffset_hint"))+" ")]),_c('v-select',{attrs:{"items":_vm.timeZoneOffsets,"outlined":"","dense":""},model:{value:(_vm.timeZoneOffset),callback:function ($$v) {_vm.timeZoneOffset=$$v},expression:"timeZoneOffset"}}),_c('div',{staticClass:"text-h6 mb-2"},[_vm._v(" "+_vm._s(_vm.$t("__store_autoreplyordertime_week"))+" ")]),_c('div',{staticClass:"d-flex mb-2"},[_c('v-sheet',{attrs:{"width":"20px","height":"20px","color":"green"}}),_c('div',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.$t("__store_autoreplyordertime_accept")))]),_c('v-sheet',{staticClass:"ml-6",attrs:{"width":"20px","height":"20px","color":"red"}}),_c('div',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.$t("__store_autoreplyordertime_reject")))])],1),_c('div',{staticClass:"subtitle-2 primary--text"},[_vm._v(" "+_vm._s(_vm.$t("__store_autoreplyordertime_setting_hint"))+" ")]),_c('v-form',{ref:"form",model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('table',{staticClass:"time-table",attrs:{"border":"1"}},[_c('tr',[_c('td'),_vm._l((7),function(d){return [_c('th',{key:d,staticClass:"time-table__header-cell"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t(("__store_autoreplyordertime_weekday_" + (d - 1))))+" ")])])]})],2),_vm._l((_vm.intervalsStatus[0]),function(_,t){return _c('tr',{key:t},[_c('th',{class:{
              'time-table__header-cell': true,
              'time-table__time-cell': true,
              'time-table__mid-time-cell':
                t === Math.floor(_vm.numOfTimeInterval / 2) - 1
            }},[_c('div',[_vm._v(_vm._s(_vm.intervalIndexToString(t)))])]),_vm._l((_vm.intervalsStatus),function(weekdayIntervalsStatus,d){return [_c('td',{key:("interval-" + d + "-" + t),class:{
                'time-table__status-cell': true,
                'time-table__mid-time-cell':
                  t === Math.floor(_vm.numOfTimeInterval / 2) - 1
              },on:{"mousedown":function($event){return _vm.intervalMouseDown(d, t, $event)},"mouseenter":function($event){return _vm.intervalMouseEnter(d, t, $event)}}},[_c('div',[_c('v-sheet',{attrs:{"width":"100%","height":"100%","color":weekdayIntervalsStatus[t] === 1
                      ? 'green'
                      : weekdayIntervalsStatus[t] === 2
                      ? 'red'
                      : 'white'}})],1)])]})],2)})],2)])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.cancel}},[_vm._v(_vm._s(_vm.$t("__cancel")))]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":!_vm.isValid},on:{"click":_vm.ok}},[_vm._v(_vm._s(_vm.$t("__confirm")))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }