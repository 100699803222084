<template>
  <v-card class="mx-auto">
    <v-toolbar color="primary" dark flat>
      <v-toolbar-title>{{ $t("__store_payment_methods") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn class="ml-2" outlined @click="toAddPaymentMethod">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-data-table dense :headers="headers" :items="paymentMethodsStoreDatas">
        <template v-slot:[`item.data.type`]="{ item }">
          {{ $t(`__store_payment_method_${item.data.type}`) }}
        </template>
        <template v-slot:[`item.data.data`]="{ item }">
          {{
            item.data.type === "transfer"
              ? `(${item.data.data.bank_code})${item.data.data.account}`
              : " - "
          }}
        </template>

        <template v-slot:[`item.data.enabled`]="{ item }">
          <v-icon v-if="item.data.enabled" color="green"
            >mdi-checkbox-marked-circle</v-icon
          >
          <v-icon v-else>mdi-close-circle</v-icon>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            class="ma-2"
            small
            color="darken-2"
            @click="toSetPaymentMethodStore(item)"
            >mdi-pencil</v-icon
          >
          <v-icon
            class="ma-2"
            small
            color="red darken-2"
            @click="toDeletePaymentMethodStore(item)"
            >mdi-delete</v-icon
          >
        </template>
      </v-data-table>
    </v-card-text>

    <v-dialog v-model="paymentMethodStoreFormShow" persistent max-width="600px">
      <PaymentMethodStoreForm
        :paymentMethodStoreData="paymentMethodStoreData"
        :action="paymentMethodStoreAction"
        @ok="storePaymentMethodStoreFormOk"
        @cancel="storePaymentMethodStoreFormCancel"
      />
    </v-dialog>
    <AlertDialog
      :show="alertDialogShow"
      :title="alertDialogTitle"
      :text="alertDialogText"
      :oktext="$t('__confirm')"
      :canceltext="$t('__cancel')"
      @ok="alertDialogOk"
      @cancel="alertDialogCancel"
    />
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import PaymentMethodStoreForm from "@/components/Store/PaymentMethodStoreForm";
import AlertDialog from "@/components/AlertDialog";

export default {
  components: {
    PaymentMethodStoreForm,
    AlertDialog
  },
  computed: {
    ...mapGetters({
      storeData: "store/data",
      paymentMethodStoreDatas: "store/paymentMethodStoreData"
    })
  },
  data() {
    return {
      headers: [
        {
          text: `${this.$t("__store_payment_method_type")}`,
          value: "data.type",
          align: "start"
        },
        {
          text: `${this.$t("__store_payment_method_name")}`,
          value: "data.name",
          align: "start",
          sortable: false
        },
        {
          text: `${this.$t("__store_payment_method_transfer_account")}`,
          value: "data.data",
          align: "start"
        },
        {
          text: `${this.$t("__store_payment_method_enable")}`,
          value: "data.enabled",
          align: "start"
        },
        {
          text: `${this.$t("__store_payment_method_actions")}`,
          value: "actions",
          align: "center",
          sortable: false
        }
      ],
      paymentMethodsStoreDatas: [],
      paymentMethodStoreData: null,
      paymentMethodStoreFormShow: false,
      paymentMethodStoreAction: "create",
      paymentMethodID: "",
      paymentMethodDeleteAlertDialogShow: false,
      paymentMethodDeleteAlertDialogID: "",
      alertDialogShow: false,
      alertDialogTitle: "",
      alertDialogText: ""
    };
  },
  methods: {
    toAddPaymentMethod() {
      this.paymentMethodStoreAction = "create";
      this.paymentMethodID = "";
      this.paymentMethodStoreData = null;
      this.paymentMethodStoreFormShow = true;
    },
    toSetPaymentMethodStore(item) {
      this.paymentMethodStoreAction = "edit";
      this.paymentMethodID = item.paymentID;
      this.paymentMethodStoreData = item.data;
      this.paymentMethodStoreFormShow = true;
    },
    toDeletePaymentMethodStore(item) {
      this.alertDialogShow = true;
      this.paymentMethodDeleteAlertDialogID = item.paymentID;
      (this.alertDialogTitle = this.$t("__delete_payment_method_title")),
        (this.alertDialogText = this.$t("__delete_payment_method_text").replace(
          "%1",
          this.$t(`__store_payment_method_${item.data.type}`)
        ));
    },
    storePaymentMethodStoreFormOk({
      enabled,
      type,
      name,
      bankCode,
      account,
      method,
      appID,
      appKey,
      partnerKey,
      merchantID,
      googleMerchantID,
      appleMerchantIdentifier,
      hashKey,
      hashIV
    }) {
      this.paymentMethodStoreFormShow = false;
      if (this.paymentMethodStoreAction === "create") {
        this.$emit("addStorePaymentMethodData", {
          enabled,
          type,
          name,
          bankCode,
          account,
          method,
          appID,
          appKey,
          partnerKey,
          merchantID,
          googleMerchantID,
          appleMerchantIdentifier,
          hashKey,
          hashIV
        });
      } else {
        this.$emit("setStorePaymentMethodData", {
          paymentMethodID: this.paymentMethodID,
          setData: {
            enabled,
            type,
            name,
            bank_code: bankCode,
            account,
            method,
            app_id: appID,
            app_key: appKey,
            partner_key: partnerKey,
            merchant_id: merchantID,
            google_merchant_id: googleMerchantID,
            apple_merchant_identifier: appleMerchantIdentifier,
            hash_key: hashKey,
            hash_iv: hashIV
          }
        });
      }
    },
    storePaymentMethodStoreFormCancel() {
      this.paymentMethodStoreFormShow = false;
    },
    alertDialogOk() {
      this.alertDialogShow = false;
      this.$emit("deleteStorePaymentMethodData", {
        paymentMethodID: this.paymentMethodDeleteAlertDialogID
      });
      this.alertDialogTitle = "";
      this.alertDialogText = "";
      this.alertDialogData = null;
    },
    alertDialogCancel() {
      this.alertDialogShow = false;
      this.alertDialogTitle = "";
      this.alertDialogText = "";
      this.alertDialogData = null;
    }
  },
  watch: {
    paymentMethodStoreDatas: {
      immediate: true,
      handler(val) {
        if (val) {
          this.paymentMethodsStoreDatas = val;
        } else {
          this.paymentMethodsStoreDatas = [];
        }
      }
    }
  }
};
</script>
