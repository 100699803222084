<template>
  <div class="store">
    <v-toolbar color="primary" dark flat>
      <v-select
        outlined
        dense
        hide-details
        :label="$t('__store_select_label')"
        :items="stores"
        item-text="name"
        item-value="id"
        v-model="storeSelect"
        @change="changeStore"
      />
      <template v-slot:extension>
        <v-tabs background-color="primary" v-model="tab" centered>
          <v-tabs-slider color="white"></v-tabs-slider>
          <v-tab key="categoriesanditems">{{
            $t("__store_categoriesanditems")
          }}</v-tab>
          <v-tab key="info">{{ $t("__store_info") }}</v-tab>
          <v-tab key="settings">{{ $t("__store_settings") }}</v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
    <!-- <v-container class="content" fluid>
      <v-row align="center" justify="center" dense>
        <v-col cols="12" sm="12" lg="12"> -->
    <v-tabs-items v-model="tab">
      <v-tab-item key="categoriesanditems">
        <v-container>
          <v-row dense>
            <v-col cols="12">
              <CategoryList
                @createCategory="createCategory"
                @updateCategory="updateCategory"
                @deleteCategory="deleteCategory"
                @selectedCategories="selectedCategories"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <ItemList
                :filterCategoryIDs="itemsFilterCategoryIDs"
                @reloadItems="loadItems"
                @createItem="createItem"
                @updateItem="updateItem"
                @publishItem="publishItem"
                @delistItem="delistItem"
                @deleteItem="deleteItem"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item key="info">
        <v-container>
          <v-row dense>
            <v-col cols="12">
              <StoreInfo @update="updateStoreInfo" />
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item key="settings">
        <v-container>
          <v-row dense>
            <v-col cols="12">
              <Messengers
                @updateMessengerTelegramBot="updateMessengerTelegramBot"
                @enableMessengerTelegramBot="enableMessengerTelegramBot"
                @disableMessengerTelegramBot="disableMessengerTelegramBot"
                :telegramBotEnabling="telegramBotEnabling"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <PaymentMethods
                @addStorePaymentMethodData="addStorePaymentMethodData"
                @setStorePaymentMethodData="setStorePaymentMethodData"
                @deleteStorePaymentMethodData="deleteStorePaymentMethodData"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <ShippingChannels
                @addShippingChannel="addShippingChannel"
                @setShippingChannel="setShippingChannel"
                @deleteShippingChannel="deleteShippingChannel"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <AutoReplyOrderTime @toSet="setAutoReplyOrderTime" />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <OnePageWeb @update="updateOnePageWeb" />
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
    </v-tabs-items>
    <!-- </v-col>
      </v-row> -->
    <alert :title="alertTitle" :color-type="'error'" :alert-open="alertShow" />
    <!-- </v-container> -->
  </div>
</template>

<script>
import CategoryList from "@/components/Store/CategoryList";
import ItemList from "@/components/Store/ItemList";
import StoreInfo from "@/components/Store/StoreInfo";
import Messengers from "@/components/Store/Messengers";
import PaymentMethods from "@/components/Store/PaymentMethods";
import ShippingChannels from "@/components/Store/ShippingChannels";
import AutoReplyOrderTime from "@/components/Store/AutoReplyOrderTime";
import OnePageWeb from "@/components/Store/OnePageWeb";
import { mapGetters } from "vuex";

export default {
  name: "Store",
  components: {
    CategoryList,
    ItemList,
    StoreInfo,
    Messengers,
    PaymentMethods,
    ShippingChannels,
    AutoReplyOrderTime,
    OnePageWeb
  },
  computed: {
    ...mapGetters({
      storeID: "store/id",
      stores: "store/datas"
    })
  },
  data() {
    return {
      tab: null,
      itemsFilterCategoryIDs: [],
      alertTitle: "",
      alertShow: false,
      telegramBotEnabling: false,
      storeSelect: ""
    };
  },
  methods: {
    loadStore() {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("store/fetchStore", this.$route.params.id)
        .then(() => {
          this.$store.dispatch("setIsLoading", false);
        })
        .catch(err => {
          console.error(err);
          if (err.indexOf("one page web data not found") !== -1) {
            this.$store.dispatch("setIsLoading", false);
          } else {
            this.$router.replace("/");
            this.$store.dispatch("setIsLoading", false);
          }
        });
    },
    loadItems() {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("store/getStoreItems")
        .then(() => {
          this.$store.dispatch("setIsLoading", false);
        })
        .catch(err => {
          console.error(err);
          this.$router.replace("/stores");
        });
    },
    loadCategories() {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("store/getStoreCategories")
        .then(() => {
          this.$store.dispatch("setIsLoading", false);
        })
        .catch(err => {
          console.error(err);
          this.$router.replace("/stores");
        });
    },
    loadMessengers() {
      return this.$store
        .dispatch("store/getStoreMessengers")
        .then(() => {
          return this.$store.dispatch("store/checkStoreTelegramBotEnabled");
        })
        .then(() => {
          this.$store.dispatch("setIsLoading", false);
        })
        .catch(err => {
          console.error(err);
          this.$router.replace("/stores");
        });
    },
    loadPaymentMethodDatas() {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("store/getStorePaymentMethodDatas")
        .then(() => {
          this.$store.dispatch("setIsLoading", false);
        })
        .catch(err => {
          console.error(err);
          this.$router.replace("/stores");
        });
    },
    loadShippingChannelDatas() {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("store/getStoreShippingChannelDatas")
        .then(() => {
          this.$store.dispatch("setIsLoading", false);
        })
        .catch(err => {
          console.error(err);
          this.$router.replace("/stores");
        });
    },
    loadAutoReplyOrderTime() {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("store/getAutoReplyOrderTime")
        .then(() => {
          this.$store.dispatch("setIsLoading", false);
        })
        .catch(err => {
          console.error(err);

          this.$router.replace("/stores");
        });
    },
    loadOnePageWebDatas() {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("store/getOnePageWebData")
        .then(() => {
          this.$store.dispatch("setIsLoading", false);
        })
        .catch(err => {
          this.$store.dispatch("setIsLoading", false);
          console.error(err);
        });
    },
    createCategory({ name, photoURLs }) {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("store/createCategory", { name, photoURLs })
        .then(() => {
          return this.loadCategories();
        })
        .catch(err => {
          console.log(err);
        });
    },
    updateCategory({ id, setData }) {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("store/updateCategory", { id, setData })
        .then(() => {
          return this.loadCategories();
        })
        .catch(err => {
          console.log(err);
        });
    },
    deleteCategory(id) {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("store/deleteCategory", id)
        .then(() => {
          return this.loadCategories();
        })
        .then(() => {
          return this.loadItems();
        })
        .catch(err => {
          console.log(err);
        });
    },
    selectedCategories(selectedCategoryIDs) {
      this.itemsFilterCategoryIDs = selectedCategoryIDs;
    },
    reloadItem() {
      this.loadItems();
    },
    createItem({
      name,
      description,
      introduction,
      categories,
      photoURLs,
      price,
      quantityOfStock,
      variations
    }) {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("store/createItem", {
          name,
          description,
          introduction,
          photoURLs,
          price,
          quantityOfStock,
          variations
        })
        .then(id => {
          return this.$store.dispatch("store/setItemCategories", {
            id,
            categories
          });
        })
        .then(() => {
          return this.loadCategories();
        })
        .then(() => {
          return this.loadItems();
        })
        .catch(err => {
          console.log(err);
        });
    },
    updateItem({
      id,
      name,
      description,
      introduction,
      categories,
      photoURLs,
      price,
      quantityOfStock,
      variations
    }) {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("store/setItem", {
          id,
          name,
          description,
          introduction,
          photoURLs,
          price,
          quantityOfStock,
          variations
        })
        .then(() => {
          return this.$store.dispatch("store/setItemCategories", {
            id,
            categories
          });
        })
        .then(() => {
          for (var category of categories)
            if (!category.id) return this.loadCategories();
          return new Promise(resolve => resolve());
        })
        .then(() => {
          return this.loadItems();
        })
        .catch(err => {
          console.log(err);
        });
    },
    publishItem(id) {
      this.$store.dispatch("setIsLoading", true);
      this.$store
        .dispatch("store/publishItem", id)
        .then(() => {
          return this.loadItems();
        })
        .catch(err => {
          console.log(err);
        });
    },
    delistItem(id) {
      this.$store.dispatch("setIsLoading", true);
      this.$store
        .dispatch("store/delistItem", id)
        .then(() => {
          return this.loadItems();
        })
        .catch(err => {
          console.log(err);
        });
    },
    deleteItem(id) {
      this.$store.dispatch("setIsLoading", true);
      this.$store
        .dispatch("store/deleteItem", id)
        .then(() => {
          return this.loadItems();
        })
        .catch(err => {
          console.log(err);
        });
    },
    updateStoreInfo({
      name,
      description,
      introduction,
      photoURLs,
      contactUs,
      termsAndConditions,
      returnPolicy
    }) {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("store/updateStoreInfo", {
          name,
          description,
          introduction,
          photoURLs,
          contactUs,
          termsAndConditions,
          returnPolicy
        })
        .then(() => {
          return this.loadStore();
        })
        .catch(err => {
          console.log(err);
        });
    },
    updateMessengerTelegramBot({ token }) {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("store/updateMessengerTelegramBot", { token })
        .then(() => {
          return this.loadMessengers();
        })
        .catch(err => {
          this.alertTitle = this.$t("__no_plan_alert_title");
          this.alertShow = true;
          setTimeout(() => {
            this.alertShow = false;
            this.alertTitle = "";
          }, 5000);
          this.$store.dispatch("setIsLoading", false);
          console.log(err);
        });
    },
    enableMessengerTelegramBot() {
      this.telegramBotEnabling = true;
      this.$store
        .dispatch("store/enableMessengerTelegramBot")
        .then(() => {
          return this.$store.dispatch("store/checkStoreTelegramBotEnabled");
        })
        .then(() => {
          this.telegramBotEnabling = false;
        })
        .catch(err => {
          this.telegramBotEnabling = false;
          console.log(err);
        });
    },
    disableMessengerTelegramBot() {
      this.telegramBotEnabling = true;
      this.$store
        .dispatch("store/disableMessengerTelegramBot")
        .then(() => {
          return this.$store.dispatch("store/checkStoreTelegramBotEnabled");
        })
        .then(() => {
          this.telegramBotEnabling = false;
        })
        .catch(err => {
          this.telegramBotEnabling = false;
          console.log(err);
        });
    },
    addStorePaymentMethodData({
      enabled,
      type,
      name,
      bankCode,
      account,
      method,
      appID,
      appKey,
      partnerKey,
      merchantID,
      googleMerchantID,
      appleMerchantIdentifier,
      hashKey,
      hashIV
    }) {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("store/addStorePaymentMethodData", {
          enabled,
          type,
          name,
          bankCode,
          account,
          method,
          appID,
          appKey,
          partnerKey,
          merchantID,
          googleMerchantID,
          appleMerchantIdentifier,
          hashKey,
          hashIV
        })
        .then(() => {
          return this.loadPaymentMethodDatas();
        })
        .catch(err => {
          console.log(err);
        });
    },
    setStorePaymentMethodData({ paymentMethodID, setData }) {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("store/setPaymentMethodStoreData", {
          paymentMethodID,
          setData
        })
        .then(() => {
          return this.loadPaymentMethodDatas();
        })
        .catch(err => {
          console.log(err);
        });
    },
    deleteStorePaymentMethodData({ paymentMethodID }) {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("store/deletePaymentMethodStoreData", {
          paymentMethodID
        })
        .then(() => {
          return this.loadPaymentMethodDatas();
        })
        .catch(err => {
          console.log(err);
        });
    },
    addShippingChannel({ enabled, type, name, price }) {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("store/addStoreShippingChannel", {
          enabled,
          type,
          name,
          price
        })
        .then(() => {
          this.loadShippingChannelDatas();
          this.$store.dispatch("setIsLoading", false);
        })
        .catch(err => {
          console.log(err);
        });
    },
    setShippingChannel({ shippingChannelID, setData }) {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("store/setStoreShippingChannelData", {
          shippingChannelID,
          setData
        })
        .then(() => {
          this.loadShippingChannelDatas();
          this.$store.dispatch("setIsLoading", false);
        })
        .catch(err => {
          console.log(err);
        });
    },
    deleteShippingChannel({ shippingChannelID }) {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("store/deleteStoreShippingChannelData", {
          shippingChannelID
        })
        .then(() => {
          this.loadShippingChannelDatas();
          this.$store.dispatch("setIsLoading", false);
        })
        .catch(err => {
          console.log(err);
        });
    },
    setAutoReplyOrderTime(data) {
      this.$store.dispatch("setIsLoading", true);
      let promise;
      if (data.mode === "week") {
        promise = this.$store.dispatch("store/setAutoReplyOrderTime_week", {
          timeZoneOffset: data.timeZoneOffset,
          weekdayTimeIntervals: data.weekdayTimeIntervals
        });
      }
      return promise
        .then(() => {
          return this.loadAutoReplyOrderTime();
        })
        .catch(err => {
          console.error(err);
        });
    },
    updateOnePageWeb({ mediaUrls, links, tables, recommendedItems, qa }) {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("store/setOnePageWebData", {
          mediaUrls,
          links,
          tables,
          recommendedItems,
          qa
        })
        .then(() => {
          this.loadOnePageWebDatas();
        })
        .catch(err => {
          console.log(err);
        });
    },
    changeStore() {
      this.$router.replace({ name: "Store", params: { id: this.storeSelect } });
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(val) {
        this.loadStore();
        let routeID = val.params.id;
        this.storeSelect = routeID;
        localStorage.setItem("storeID", routeID);
        this.$store.commit("store/SET_STORE_HISTORY_ID", routeID);
      }
    }
  }
};
</script>

<style lang="scss">
.store {
  height: 100%;
}
.content {
  position: absolute;
  top: 48px;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
}
</style>
