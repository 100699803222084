<template>
  <v-card class="mx-auto">
    <v-toolbar color="primary" dark flat>
      <v-toolbar-title>{{ $t("__store_messengers") }}</v-toolbar-title>
      <v-spacer />
      <v-btn outlined @click="copyTelegramLink" v-if="telegramBotID">
        {{ $t("__copy_link") }}
        <v-icon small class="ml-1">mdi-content-copy</v-icon></v-btn
      >
    </v-toolbar>
    <v-card-text>
      <div class="telegram-bot-data-container">
        <div
          class="
            telegram-bot-data-container__header
            telegram-bot-data-container__line
          "
        >
          {{ $t("__store_messengers_telegrambot") }}
        </div>
        <!-- set telegram bot -->
        <template v-if="!telegramBotEnabled">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="telegram-bot-data-container__edit-btn"
                fab
                dark
                small
                color="primary"
                @click="toSetMessengerTelegramBot"
                :loading="telegramBotEnabling"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-cog</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("__set") }}</span>
          </v-tooltip>
        </template>
        <!-- disable telegram bot -->
        <template v-if="telegramBotEnabled">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="telegram-bot-data-container__enable-btn"
                fab
                dark
                small
                color="red"
                @click="disableMessengerTelegramBot"
                :loading="telegramBotEnabling"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-power</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("__store_messengers_disabletelegrambot") }}</span>
          </v-tooltip>
        </template>
        <!-- enable telegram bot -->
        <template v-else>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="telegram-bot-data-container__enable-btn"
                fab
                dark
                small
                color="green"
                @click="enableMessengerTelegramBot"
                :loading="telegramBotEnabling"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-power</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("__store_messengers_enabletelegrambot") }}</span>
          </v-tooltip>
        </template>
        <template v-if="telegramBotLink.length > 0">
          <qrcode-vue
            class="telegram-bot-data-container__qrcode"
            :value="telegramBotLink"
            size="100"
            renderAs="canvas"
          />
        </template>
        <table
          class="
            telegram-bot-data-container__table telegram-bot-data-container__line
          "
        >
          <tr>
            <th>
              <div>{{ $t("__store_messengers_telegrambot_status") }}</div>
            </th>
            <td>
              <template v-if="telegramBotEnabled">
                {{ $t("__store_messengers_telegrambot_status_enabled") }}
                <v-icon dark color="green">mdi-check-circle</v-icon>
              </template>
              <template v-else>
                {{ $t("__store_messengers_telegrambot_status_disabled") }}
                <v-icon dark color="red">mdi-close-circle</v-icon>
              </template>
            </td>
            <td rowspan="2"></td>
          </tr>
          <tr>
            <th>
              <div>{{ $t("__store_messengers_telegrambot_bot_id") }}</div>
            </th>
            <td>
              <div>{{ telegramBotID }}</div>
            </td>
            <td rowspan="2"></td>
          </tr>
          <tr>
            <th>
              <div>{{ $t("__store_messengers_telegrambot_token") }}</div>
            </th>
            <td>
              <div>{{ telegramToken }}</div>
            </td>
          </tr>
          <tr>
            <th>
              <div>{{ $t("__store_messengers_telegrambot_numofchats") }}</div>
            </th>
            <td>
              <div>{{ numOfChats }}</div>
            </td>
          </tr>
        </table>
      </div>
    </v-card-text>

    <v-dialog
      v-model="messengerTelegramBotFormShow"
      persistent
      max-width="600px"
    >
      <MessengerTelegramBotForm
        :telegramBotData="messengerTelegramBotFormTelegramBotData"
        @ok="storeMessengerTelegramBotFormOk"
        @cancel="storeMessengerTelegramBotFormCancel"
      />
    </v-dialog>
    <alert
      :title="alertTitle"
      :color-type="alertColor"
      :alert-open="alertShow"
      :dismissible="alertDismissible"
    />
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import MessengerTelegramBotForm from "@/components/Store/MessengerTelegramBotForm";
import QrcodeVue from "qrcode.vue";

export default {
  components: {
    MessengerTelegramBotForm,
    QrcodeVue
  },
  computed: {
    ...mapGetters({
      isMobile: "isMobile",
      telegramBotData: "store/messengerTelegramBotData",
      enabledTelegramBot: "store/messengersEnabledData",
      telegramBotEnabled: "store/messengersEnabled"
    }),
    telegramBotID() {
      return this.telegramBotData ? this.telegramBotData.bot_id : "";
    },
    telegramToken() {
      return this.telegramBotData ? this.telegramBotData.token : "";
    },
    telegramBotLink() {
      return this.telegramBotData
        ? `http://t.me/${this.telegramBotData.bot_id}`
        : "";
    },
    numOfChats() {
      return this.enabledTelegramBot
        ? this.enabledTelegramBot["number_of_chats"].toString()
        : "-";
    }
  },
  props: {
    telegramBotEnabling: {
      type: Boolean
    }
  },
  data() {
    return {
      messengerTelegramBotFormShow: false,
      messengerTelegramBotFormTelegramBotData: null,
      alertTitle: "",
      alertShow: false,
      alertColor: "error",
      alertDismissible: false
    };
  },
  methods: {
    toSetMessengerTelegramBot() {
      this.messengerTelegramBotFormTelegramBotData = this.telegramBotData;
      this.messengerTelegramBotFormShow = true;
    },
    enableMessengerTelegramBot() {
      this.$emit("enableMessengerTelegramBot");
    },
    disableMessengerTelegramBot() {
      this.$emit("disableMessengerTelegramBot");
    },
    storeMessengerTelegramBotFormOk({ token }) {
      this.messengerTelegramBotFormShow = false;
      this.$emit("updateMessengerTelegramBot", {
        token
      });
    },
    storeMessengerTelegramBotFormCancel() {
      this.messengerTelegramBotFormShow = false;
    },
    copyTelegramLink() {
      navigator.clipboard.writeText(this.telegramBotLink).then(() => {
        this.alertShow = true;
        this.alertDismissible = false;
        this.alertColor = "success";
        this.alertTitle = this.$t("__copy_successful");
        setTimeout(() => {
          this.alertShow = false;
          this.alertTitle = "";
        }, 5000);
      });
    }
  }
};
</script>

<style lang="scss">
.telegram-bot-data-container {
  position: relative;
  min-height: 156px;

  &__line {
    margin-block: 1em;
  }
  &__header {
    font-size: 1.5em;
    font-weight: bold;
  }
  &__edit-btn {
    position: absolute;
    top: 0;
    right: 48px;
  }
  &__enable-btn {
    position: absolute;
    top: 0;
    right: 0;
  }
  &__qrcode {
    position: absolute;
    top: 56px;
    right: 0;
  }
  &__table {
    max-width: calc(100% - 100px);
    th {
      white-space: nowrap;
      text-align: left;
    }
    td {
      padding: 0.2em;
      word-wrap: break-word;
    }
  }
  @media (max-width: 600px) {
    &__qrcode {
      position: relative;
      top: 0;
      right: 0;
    }
    &__table {
      max-width: 100%;
      td {
        word-break: break-all;
      }
    }
  }
}
</style>
