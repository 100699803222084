<template>
  <v-card v-if="onePageWeb">
    <v-toolbar color="primary" dark flat>
      <v-toolbar-title>{{
        $t("__store_one_web_page_settings")
      }}</v-toolbar-title>
      <v-spacer />
      <template v-if="onePageWebData">
        <v-btn outlined class="mr-2" @click="copyOnePageWebLink">
          {{ $t("__copy_link") }}
          <v-icon small class="ml-1">mdi-content-copy</v-icon></v-btn
        >
        <v-btn link outlined @click="toOneWebPage">
          {{ $t("__store_one_web_page_view") }}
          <v-icon class="ml-1" small>mdi-open-in-new</v-icon>
        </v-btn>
      </template>
    </v-toolbar>

    <v-card-text>
      <v-form ref="form" v-model="isValid">
        <table class="info-table">
          <tr>
            <td colspan="2">
              <div class="my-4 text-h5 font-weight-black">
                {{ $t("__store_one_web_page_store_info") }}
              </div>
            </td>
          </tr>
          <!-- background image -->
          <tr>
            <th>
              {{ $t("__store_one_web_page_bg_image") }}
              <span class="red--text"> *</span>
            </th>
            <td>
              <photo-url-input
                dense
                outlined
                required
                :rules="[requiredRule]"
                v-model="form.mediaUrls['background_image']"
                :hint="$t('__store_one_web_page_bg_image_pixels')"
                :persistentHint="true"
              />
            </td>
          </tr>
          <!-- main image -->
          <tr>
            <th>
              {{ $t("__store_one_web_page_main_image")
              }}<span class="red--text"> *</span>
            </th>
            <td>
              <photo-url-input
                dense
                outlined
                required
                :rules="[requiredRule]"
                v-model="form.mediaUrls['main_image']"
              />
            </td>
          </tr>
          <!-- youtube video -->
          <tr>
            <th>
              {{ $t("__store_one_web_page_video") }}
            </th>
            <td>
              <v-text-field
                dense
                outlined
                persistent-hint
                :hint="$t('__store_one_web_page_video_hint')"
                v-model="form.mediaUrls['introduction_youtube']"
              />
            </td>
          </tr>

          <!-- social link -->
          <tr>
            <th>
              {{ $t("__store_one_web_page_fb") }}
            </th>
            <td>
              <v-text-field dense outlined v-model="form.links['fb']" />
            </td>
          </tr>
          <tr>
            <th>
              {{ $t("__store_one_web_page_ig") }}
            </th>
            <td>
              <v-text-field dense outlined v-model="form.links['ig']" />
            </td>
          </tr>
          <tr>
            <td colspan="2"><v-divider class="my-4" /></td>
          </tr>
          <!-- Items info -->
          <template v-if="industryType === 'retail' || !industryType">
            <tr>
              <td colspan="2">
                <div class="my-4 text-h5 font-weight-black">
                  {{ $t("__store_one_web_page_item_info") }}
                </div>
              </td>
            </tr>
            <!-- recommended items -->
            <tr>
              <th>
                {{ $t("__store_one_web_page_recommended_item_by_manager") }}
                <span class="red--text">*</span>
              </th>
              <td>
                <v-combobox
                  outlined
                  dense
                  required
                  :rules="[requiredRule]"
                  :items="itemOptions"
                  item-text="name"
                  item-value="id"
                  v-model="recommendedItemsBymanager"
                />
              </td>
            </tr>
            <tr>
              <th>
                {{ $t("__store_one_web_page_recommended_item_new") }}
                <span class="red--text">*</span>
              </th>
              <td>
                <v-combobox
                  outlined
                  dense
                  required
                  :rules="[requiredRule]"
                  :items="itemOptions"
                  item-text="name"
                  item-value="id"
                  v-model="recommendedItemsNew"
                />
              </td>
            </tr>
            <tr>
              <td colspan="2"><v-divider class="my-4" /></td>
            </tr>
            <!-- comparison table -->
            <tr>
              <td colspan="3">
                <div class="my-4 text-h5 font-weight-black">
                  {{ $t("__store_one_web_page_tables") }}
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <td colspan="5" class="text-right">
                          <v-btn color="primary" @click="addTableRow">{{
                            $t("__create")
                          }}</v-btn>
                        </td>
                      </tr>
                      <tr>
                        <th class="text-left">
                          {{ $t("__store_one_web_page_tables_title") }}
                        </th>
                        <th class="text-left">
                          {{ $t("__store_one_web_page_tables_our_store") }}
                        </th>
                        <th class="text-left">
                          {{ $t("__store_one_web_page_tables_A_store") }}
                        </th>
                        <th class="text-left">
                          {{ $t("__store_one_web_page_tables_B_store") }}
                        </th>
                        <th class="text-left">
                          {{ $t("__actions") }}
                        </th>
                      </tr>
                    </thead>
                    <tbody class="formTable">
                      <tr v-for="(item, i) in tables" :key="i">
                        <td class="pt-5">
                          <v-text-field
                            dense
                            outlined
                            v-model="item.feature.texts"
                          />
                        </td>
                        <td class="pt-5">
                          <div class="d-flex align-center">
                            <v-text-field
                              class="mr-1"
                              dense
                              outlined
                              v-model="item.our.texts"
                            />
                            <v-checkbox
                              class="mt-0"
                              v-model="item.our.checked"
                            />
                          </div>
                        </td>
                        <td class="pt-5">
                          <div class="d-flex align-center">
                            <v-text-field
                              class="mr-1"
                              dense
                              outlined
                              v-model="item.aStore.texts"
                            />
                            <v-checkbox
                              class="mt-0"
                              v-model="item.aStore.checked"
                            />
                          </div>
                        </td>
                        <td class="pt-5">
                          <div class="d-flex align-center">
                            <v-text-field
                              class="mr-1"
                              dense
                              outlined
                              v-model="item.bStore.texts"
                            />
                            <v-checkbox
                              class="mt-0"
                              v-model="item.bStore.checked"
                            />
                          </div>
                        </td>
                        <td>
                          <v-btn icon @click="deleteTableRow(i)">
                            <v-icon color="red darken-2">mdi-delete</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </td>
            </tr>
            <tr>
              <td colspan="2"><v-divider class="my-4" /></td>
            </tr>
          </template>
          <!-- services table -->
          <template
            v-if="industryType === 'service' || industryType === 'catering'"
          >
            <tr>
              <td colspan="2">
                <div class="my-4 text-h5 font-weight-black">
                  {{ $t("__store_one_web_page_tables_services") }}
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <v-combobox
                  :items="itemOptions"
                  item-text="name"
                  item-value="id"
                  :label="$t('__store_one_web_page_tables_services_text')"
                  :hint="$t('__store_one_web_page_tables_services_hint')"
                  persistent-hint
                  multiple
                  dense
                  outlined
                  v-model="servicesSelect"
                >
                  <template v-slot:selection="{ item }">
                    <v-chip small dark color="info" v-if="item.name">
                      {{ item.name }}
                    </v-chip>
                  </template>
                </v-combobox>
              </td>
            </tr>
            <tr>
              <td colspan="2"><v-divider class="my-4" /></td>
            </tr>
          </template>
          <!-- Q&A -->
          <tr>
            <td colspan="3">
              <div class="my-4 text-h5 font-weight-black">
                {{ $t("__store_one_web_page_QA") }}
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="3" class="text-right">
              <v-btn color="primary" @click="addQA">{{ $t("__create") }}</v-btn>
            </td>
          </tr>
          <template v-for="(_, i) in form.qa">
            <tr :key="`question${i}`">
              <th class="pt-3">
                {{ $t("__store_one_web_page_Q") + (i + 1) }}
              </th>
              <td class="pt-3">
                <v-text-field
                  dense
                  outlined
                  v-model.trim="form.qa[i].question"
                />
              </td>
              <td rowspan="2" class="pl-3" width="5%">
                <v-btn icon @click="deleteQA(i)">
                  <v-icon color="red darken-2">mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
            <tr :key="`answer${i}`">
              <th>
                {{ $t("__store_one_web_page_A") + (i + 1) }}
              </th>
              <td>
                <v-text-field dense outlined v-model.trim="form.qa[i].answer" />
              </td>
            </tr>
            <tr :key="`divider${i}`" v-if="form.qa.length > 1">
              <td colspan="2">
                <v-divider />
              </td>
            </tr>
          </template>
        </table>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="update" :disabled="!isValid">{{
        $t("__update")
      }}</v-btn>
    </v-card-actions>
    <alert
      :title="alertTitle"
      :color-type="alertColor"
      :alert-open="alertShow"
      :dismissible="alertDismissible"
    />
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      storeItems: "store/items",
      onePageWebData: "store/onePageWebData",
      categories: "store/categories",
      industryType: "store/storeIndustryType",
      //user limit
      onePageWeb: "store/onePageWeb",
      telegramBotData: "store/messengerTelegramBotData"
    }),
    itemOptions() {
      let option;
      option = this.storeItems.filter(item => {
        if (item.data.published_time) {
          return item.data.published_time.nanoseconds !== 0;
        }
      });
      let optionList = [];
      for (const item of option) {
        optionList.push({
          id: item.id,
          name: item.data.name,
          categoryIDs: item.data["category_ids"].join(",")
        });
      }
      return optionList;
    },
    telegramBotID() {
      return this.telegramBotData ? this.telegramBotData.bot_id : "";
    }
  },
  data() {
    return {
      form: {
        mediaUrls: {
          background_image: "",
          introduction_youtube: "",
          main_image: ""
        },
        links: {
          fb: "",
          ig: ""
        },
        tables: {
          comparison: {
            rows: 0,
            cols: 4,
            texts: [],
            checked: []
          },
          services: {
            texts: []
          },
          fees: {
            texts: []
          }
        },
        recommendedItems: {
          by_manager: [],
          new: []
        },
        qa: [
          {
            question: "",
            answer: ""
          }
        ]
      },
      tables: [
        {
          feature: {
            texts: "",
            checked: false
          },
          our: {
            texts: "",
            checked: false
          },
          aStore: {
            texts: "",
            checked: false
          },
          bStore: {
            texts: "",
            checked: false
          }
        }
      ],
      servicesSelect: [],
      recommendedItemsBymanager: "",
      recommendedItemsNew: "",
      isValid: true,
      requiredRule: v => !!v || this.$t("__required"),
      alertTitle: "",
      alertShow: false,
      alertColor: "error",
      alertDismissible: false
    };
  },
  watch: {
    onePageWebData: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.form = {
          mediaUrls: {
            background_image: "",
            introduction_youtube: "",
            main_image: ""
          },
          links: {
            fb: "",
            ig: ""
          },
          tables: {
            comparison: {
              rows: 0,
              cols: 4,
              texts: [],
              checked: []
            },
            services: {
              texts: []
            },
            fees: {
              texts: []
            }
          },
          recommendedItems: {
            by_manager: [],
            new: []
          },
          qa: [
            {
              question: "",
              answer: ""
            }
          ]
        };
        this.tables = [
          {
            feature: {
              texts: "",
              checked: false
            },
            our: {
              texts: "",
              checked: false
            },
            aStore: {
              texts: "",
              checked: false
            },
            bStore: {
              texts: "",
              checked: false
            }
          }
        ];
        this.servicesSelect = [];
        this.recommendedItemsBymanager = "";
        this.recommendedItemsNew = "";
        if (newValue) {
          this.form = {
            links: {
              fb: newValue.links.fb ? newValue.links.fb : "",
              ig: newValue.links.ig ? newValue.links.ig : ""
            },
            mediaUrls: {
              background_image: newValue["media_urls"]["background_image"]
                ? newValue["media_urls"]["background_image"]
                : "",
              introduction_youtube: newValue["media_urls"][
                "introduction_youtube"
              ]
                ? newValue["media_urls"]["introduction_youtube"]
                : "",
              main_image: newValue["media_urls"]["main_image"]
                ? newValue["media_urls"]["main_image"]
                : ""
            },
            qa: newValue.qa ? newValue.qa : [{ question: "", answer: "" }],
            tables: {
              comparison: {
                rows: 0,
                cols: 4,
                texts: [],
                checked: []
              },
              services: {
                texts: []
              },
              fees: {
                texts: []
              }
            },
            recommendedItems: {
              by_manager: [],
              new: []
            }
          };
          if (this.itemOptions) {
            // recommend items
            this.updateRecommendItems();
            // service select
            this.updateServiceItems();
          }
          // comparison table
          let comparisonTexts = newValue.tables.comparison.texts;
          let comparisonChecked = newValue.tables.comparison.checked;
          if (comparisonTexts) {
            let length = comparisonTexts.length / 4;
            for (let i = 0; i < length; i++) {
              let textAry = [];
              let checkedAry = [];
              textAry = comparisonTexts.splice(0, 4);
              checkedAry = comparisonChecked.splice(0, 4);
              this.tables.splice(i, 1, {
                feature: {
                  texts: textAry[0],
                  checked: checkedAry[0]
                },
                our: {
                  texts: textAry[1],
                  checked: checkedAry[1]
                },
                aStore: {
                  texts: textAry[2],
                  checked: checkedAry[2]
                },
                bStore: {
                  texts: textAry[3],
                  checked: checkedAry[3]
                }
              });
            }
          } else {
            this.tables = [
              {
                feature: {
                  texts: "",
                  checked: false
                },
                our: {
                  texts: "",
                  checked: false
                },
                aStore: {
                  texts: "",
                  checked: false
                },
                bStore: {
                  texts: "",
                  checked: false
                }
              }
            ];
          }
        }
      }
    },
    itemOptions: {
      handler() {
        if (this.onePageWebData) {
          // recommened Items
          this.updateRecommendItems();
          // service select
          this.updateServiceItems();
        }
      }
    },
    $route: {
      immediate: true,
      handler() {
        if (this.onePageWeb) this.loadOnePageWebData();
      }
    }
  },
  methods: {
    loadOnePageWebData() {
      this.$store.dispatch("setIsLoading", true);
      return this.$store
        .dispatch("store/getOnePageWebData")
        .then(() => {
          this.$store.dispatch("setIsLoading", false);
        })
        .catch(err => {
          this.$store.dispatch("setIsLoading", false);
          console.log(err);
        });
    },
    update() {
      this.form.recommendedItems["by_manager"].push(
        this.recommendedItemsBymanager.id
      );
      this.form.recommendedItems["new"].push(this.recommendedItemsNew.id);

      this.form.tables.comparison.rows = this.tables.length;
      for (const table of this.tables) {
        let rowAry = Object.values(table);
        for (const ary of rowAry) {
          this.form.tables.comparison.texts.push(ary.texts);
          this.form.tables.comparison.checked.push(ary.checked);
        }
      }
      for (const service of this.servicesSelect) {
        if (service.id) this.form.tables.services.texts.push(service.id);
      }
      if (!this.form.qa[0].question) {
        this.form.qa = [];
      }
      this.$emit("update", this.form);
    },
    addTableRow() {
      this.tables.push({
        feature: {
          texts: "",
          checked: false
        },
        our: {
          texts: "",
          checked: false
        },
        aStore: {
          texts: "",
          checked: false
        },
        bStore: {
          texts: "",
          checked: false
        }
      });
    },
    deleteTableRow(key) {
      if (this.tables.length === 1) return;
      this.tables.splice(key, 1);
    },
    addQA() {
      this.form.qa.push({
        question: "",
        answer: ""
      });
    },
    deleteQA(key) {
      this.form.qa.splice(key, 1);
    },
    toOneWebPage() {
      window.open(`https://www.chia-market.com/store/${this.telegramBotID}`);
    },
    copyOnePageWebLink() {
      navigator.clipboard
        .writeText(`https://www.chia-market.com/store/${this.telegramBotID}`)
        .then(() => {
          this.alertShow = true;
          this.alertDismissible = false;
          this.alertColor = "success";
          this.alertTitle = this.$t("__copy_successful");
          setTimeout(() => {
            this.alertShow = false;
            this.alertTitle = "";
          }, 5000);
        });
    },
    updateRecommendItems() {
      let managerAry = this.itemOptions.filter(item => {
        return (
          item.id === this.onePageWebData["recommended_items"]["by_manager"][0]
        );
      });
      this.recommendedItemsBymanager = managerAry[0];
      let newAry = this.itemOptions.filter(item => {
        return item.id === this.onePageWebData["recommended_items"]["new"][0];
      });
      this.recommendedItemsNew = newAry[0];
    },
    updateServiceItems() {
      if (this.onePageWebData?.tables?.services.cols) {
        this.servicesSelect = this.itemOptions.filter(item => {
          return this.onePageWebData.tables.services.texts.filter(text => {
            return item.id === text;
          });
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.info-table {
  th {
    padding-top: 0;
  }
}
.formTable {
  td:not(:last-child) {
    width: 23%;
  }
}
</style>
