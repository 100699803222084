<template>
  <div class="html-editor">
    <v-card elevation="0">
      <v-tabs
        height="36"
        background-color="transparent"
        v-model="tab">
        <v-tab key='write'>Write</v-tab>
        <v-tab key='preview'>Preview</v-tab>
      </v-tabs>
      <v-divider/>
      <v-tabs-items v-model="tab">
        <v-tab-item key='write'>
          <v-card>
            <v-toolbar dense flat>
              <html-toolbar for="textarea_id">
                <span>
                  <html-bold><v-btn outlined small><v-icon>mdi-format-bold</v-icon></v-btn></html-bold>
                  <html-italic><v-btn outlined small><v-icon>mdi-format-italic</v-icon></v-btn></html-italic>
                  <html-underline><v-btn outlined small><v-icon>mdi-format-underline</v-icon></v-btn></html-underline>
                  <html-strikethrough><v-btn outlined small><v-icon>mdi-format-strikethrough</v-icon></v-btn></html-strikethrough>
                </span>
                <span class="ml-2">
                  <html-code><v-btn outlined small><v-icon>mdi-code-tags</v-icon></v-btn></html-code>
                  <html-link><v-btn outlined small><v-icon>mdi-link</v-icon></v-btn></html-link>
                </span>
              </html-toolbar>
            </v-toolbar>
            <v-divider/>
            <v-textarea id="textarea_id" class="pa-2" hide-details :value="value" @input="update"></v-textarea>
          </v-card>
        </v-tab-item>
        <v-tab-item key='preview'>
          <v-card>
            <v-card-text>
              <div v-html="value"/>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import './html-toolbar-element'

export default {
  name: "HTMLEditor",
  props: [
    'value'
  ],
  data () {
    return {
      tab: null
    }
  },
  methods: {
    update(value) {
      this.$emit('input', value)
    }
  }
}
</script>