<template>
  <v-card class="mx-auto">
    <v-toolbar color="primary" dark flat>
      <v-toolbar-title>{{ $t("__store_categories") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn class="ml-2" outlined @click="toAddCategory">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-data-table
        dense
        :headers="headers"
        :items="categories"
        show-select
        v-model="selectedCategories"
      >
        <template v-slot:[`item.data.name`]="{ item }">
          <v-btn small text color="primary" @click="toItemTelegramBot(item.id)">
            {{ item.data.name }}</v-btn
          >
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                icon
                v-bind="attrs"
                v-on="on"
                @click="copyLink(item.id)"
                ><v-icon color="primary" small>mdi-content-copy</v-icon></v-btn
              >
            </template>
            <span>{{ $t("__copy_category_link") }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" @click="showQrcode(item)"
                ><v-icon color="primary" small>mdi-qrcode</v-icon></v-btn
              >
            </template>
            <span>{{ $t("__category_qrcode") }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            class="ma-2"
            small
            color="darken-2"
            @click="toUpdateCategory(item)"
            >mdi-pencil</v-icon
          >
          <v-icon
            class="ma-2"
            small
            color="red darken-2"
            @click="toDeleteCategory(item)"
            >mdi-delete</v-icon
          >
        </template>
      </v-data-table>
    </v-card-text>

    <v-dialog v-model="categoryFormShow" persistent max-width="600px">
      <CategoryForm
        :action="categoryFormAction"
        :categoryData="categoryFormCategoryData"
        @ok="storeCategoryDataDialogOk"
        @cancel="storeCategoryDataDialogCancel"
      />
    </v-dialog>
    <AlertDialog
      :show="categoryDeleteAlertDialogShow"
      :title="$t('__deletecategory_title')"
      :text="$t('__deletecategory_text')"
      :oktext="$t('__confirm')"
      :canceltext="$t('__cancel')"
      @ok="storeCategoryDeleteAlertDialogOk"
      @cancel="storeCategoryDeleteAlertDialogCancel"
    />
    <alert
      :title="alertTitle"
      :color-type="alertColor"
      :alert-open="alertShow"
      :dismissible="alertDismissible"
    />
    <v-dialog max-width="400px" v-model="qrcodeDialogShow" persistent>
      <QRcodeDialog
        :type="'category'"
        :title="qrcodeDialogTitle"
        :link="qrcodeDialogLink"
        :telegramBotID="telegramBotID"
        @close="closeQrcode"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import CategoryForm from "@/components/Store/CategoryForm";
import AlertDialog from "@/components/AlertDialog";
import QRcodeDialog from "@/components/QRcodeDialog";

export default {
  components: {
    CategoryForm,
    AlertDialog,
    QRcodeDialog
  },
  computed: {
    ...mapGetters({
      storeCategories: "store/categories",
      storeItems: "store/items",
      telegramBotData: "store/messengerTelegramBotData"
    }),
    categories() {
      const categories = [];
      for (const storeCategory of this.storeCategories) {
        const category = {
          id: storeCategory.id,
          data: storeCategory.data,
          numberOfItems: 0
        };
        for (const item of this.storeItems) {
          if (
            !!item.data["category_ids"] &&
            item.data["category_ids"].includes(category.id)
          )
            category.numberOfItems += 1;
        }
        categories.push(category);
      }
      return categories;
    },
    telegramBotID() {
      return this.telegramBotData ? this.telegramBotData["bot_id"] : "";
    }
  },
  data() {
    return {
      headers: [
        {
          text: `${this.$t("__categorylist_name")}`,
          value: "data.name",
          align: "start"
        },
        {
          text: `${this.$t("__categorylist_number_of_items")}`,
          value: "numberOfItems",
          align: "start"
        },
        {
          text: `${this.$t("__categorylist_actions")}`,
          value: "actions",
          align: "end",
          sortable: false
        }
      ],
      selectedCategories: [],
      categoryFormShow: false,
      categoryFormAction: "create",
      categoryFormCategoryID: "",
      categoryFormCategoryData: null,
      categoryDeleteAlertDialogShow: false,
      categoryDeleteAlertDialogCategoryID: "",
      alertTitle: "",
      alertShow: false,
      alertColor: "error",
      alertDismissible: false,
      qrcodeDialogShow: false,
      qrcodeDialogTitle: null,
      qrcodeDialogLink: ""
    };
  },
  methods: {
    toAddCategory() {
      this.categoryFormAction = "create";
      this.categoryFormCategoryID = "";
      this.categoryFormCategoryData = null;
      this.categoryFormShow = true;
    },
    toUpdateCategory(category) {
      this.categoryFormAction = "edit";
      this.categoryFormCategoryID = category.id;
      this.categoryFormCategoryData = category.data;
      this.categoryFormShow = true;
    },
    toDeleteCategory(category) {
      this.categoryDeleteAlertDialogCategoryID = category.id;
      this.categoryDeleteAlertDialogShow = true;
    },
    storeCategoryDataDialogOk({ name, photoURLs }) {
      this.categoryFormShow = false;
      if (this.categoryFormAction === "create") {
        this.$emit("createCategory", { name, photoURLs });
      } else {
        let setData = {
          name,
          photo_urls: photoURLs
        };
        this.$emit("updateCategory", {
          id: this.categoryFormCategoryID,
          setData
        });
      }
    },
    storeCategoryDataDialogCancel() {
      this.categoryFormShow = false;
    },
    storeCategoryDeleteAlertDialogOk() {
      this.categoryDeleteAlertDialogShow = false;
      this.$emit("deleteCategory", this.categoryDeleteAlertDialogCategoryID);
    },
    storeCategoryDeleteAlertDialogCancel() {
      this.categoryDeleteAlertDialogShow = false;
    },
    toItemTelegramBot(categoryID) {
      window.open(
        `https://t.me/${this.telegramBotID}?start=category_${categoryID}`
      );
    },
    copyLink(categoryID) {
      navigator.clipboard
        .writeText(
          `https://t.me/${this.telegramBotID}?start=category_${categoryID}`
        )
        .then(() => {
          this.alertShow = true;
          this.alertDismissible = false;
          this.alertColor = "success";
          this.alertTitle = this.$t("__copy_successful");
          setTimeout(() => {
            this.alertShow = false;
            this.alertTitle = "";
          }, 5000);
        });
    },
    showQrcode(category) {
      this.qrcodeDialogTitle = category.data.name;
      this.qrcodeDialogLink = `https://t.me/${this.telegramBotID}?start=category_${category.id}`;
      this.qrcodeDialogShow = true;
    },
    closeQrcode() {
      this.qrcodeDialogTitle = "";
      this.qrcodeDialogShow = false;
    }
  },
  watch: {
    selectedCategories(newValue) {
      const selectedCategoryIDs = [];
      for (const selectedCategory of newValue)
        selectedCategoryIDs.push(selectedCategory.id);
      this.$emit("selectedCategories", selectedCategoryIDs);
    }
  }
};
</script>
