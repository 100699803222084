<template>
  <v-card>
    <v-card-title>
      <span class="headline">{{ $t("__edit_messenger_telegram_bot") }}</span>
    </v-card-title>

    <v-card-text>
      <v-form ref="form" v-model="isValid">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                outlined
                dense
                v-model="form.token"
                :label="$t('__edit_messenger_telegram_bot_token') + ' *'"
                required
                :rules="[requiredRule, tokenRule]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                outlined
                dense
                v-model="form.botID"
                :label="$t('__edit_messenger_telegram_bot_bot_id') + ' *'"
                required
                :rules="[requiredRule]"
                readonly
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="cancel">{{
        $t("__cancel")
      }}</v-btn>
      <v-btn color="blue darken-1" text @click="ok" :disabled="!isValid">{{
        $t("__confirm")
      }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    telegramBotData: {
      required: true
    }
  },
  data() {
    return {
      form: {
        token: "",
        botID: ""
      },
      isValid: true,
      requiredRule: v => !!v || this.$t("__required"),
      tokenRule: v => {
        const found = v.match(/\d+:[\w|-]+/);
        return (found && found[0] === v) || this.$t("__invalid");
      }
    };
  },
  methods: {
    telegramBotGetMe(token) {
      return fetch(`https://api.telegram.org/bot${token}/getMe`);
    },
    ok() {
      this.$emit("ok", {
        token: this.form.token,
        botID: this.form.botID
      });
    },
    cancel() {
      this.$emit("cancel");
      this.form.token = "";
      this.form.botID = "";
    }
  },
  watch: {
    telegramBotData: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) {
          this.form.botID = newValue.bot_id;
          this.form.token = newValue.token;
        } else {
          this.form.token = "";
          this.form.botID = "";
        }
      }
    },
    "form.token": {
      handler(newValue) {
        if (newValue.length > 0 && this.tokenRule(newValue)) {
          this.telegramBotGetMe(newValue)
            .then(response => {
              return response.json();
            })
            .then(res => {
              console.log(res);
              if (res.ok) {
                this.form.botID = res.result.username;
              } else {
                this.form.botID = "";
              }
            })
            .catch(err => {
              console.err("telegram bot getMe error: ", err);
            });
        }
      }
    }
  }
};
</script>
