<template>
  <v-card class="mx-auto">
    <v-toolbar color="primary" dark flat>
      <v-toolbar-title>{{ $t("__store_autoreplyordertime") }}</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <div class="text-right">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="set-btn"
              fab
              dark
              small
              color="primary"
              @click="toSet"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-cog</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("__set") }}</span>
        </v-tooltip>
      </div>
      <div class="data-container">
        <template v-if="!storeAutoReplyOrderTime">
          <div style="height: 100px;" />
        </template>
        <template v-else>
          <table class="data-container__table data-container__line">
            <tr>
              <th>
                <b>{{ $t("__store_autoreplyordertime_timezoneoffset") }}</b>
              </th>
              <td>
                {{ timeZoneOffset }}
              </td>
            </tr>
            <template v-if="mode === 'week'">
              <tr>
                <th>
                  <b>{{ $t("__store_autoreplyordertime_mode") }}</b>
                </th>
                <td>
                  {{ $t(`__store_autoreplyordertime_week`) }}
                </td>
              </tr>
              <template v-if="!isMobile">
                <tr>
                  <th>
                    <b>{{
                      $t("__store_autoreplyordertime_timeintervals_accept")
                    }}</b>
                  </th>
                  <table>
                    <tr
                      v-for="(strs, k) in weekTimeIntervalsStrings('accept')"
                      :key="k"
                    >
                      <td>
                        {{ k }}
                      </td>
                      <td v-for="(str, i) in strs" :key="`${k}-${i}`">
                        {{ str }}
                      </td>
                    </tr>
                  </table>
                </tr>
                <tr>
                  <th>
                    <b>{{
                      $t("__store_autoreplyordertime_timeintervals_reject")
                    }}</b>
                  </th>
                  <table>
                    <tr
                      v-for="(strs, k) in weekTimeIntervalsStrings('reject')"
                      :key="k"
                    >
                      <td>
                        {{ k }}
                      </td>
                      <td v-for="(str, i) in strs" :key="`${k}-${i}`">
                        {{ str }}
                      </td>
                    </tr>
                  </table>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <th colspan="2">
                    <b>{{
                      $t("__store_autoreplyordertime_timeintervals_accept")
                    }}</b>
                  </th>
                </tr>
                <table>
                  <tr
                    v-for="(strs, k) in weekTimeIntervalsStrings('accept')"
                    :key="k"
                  >
                    <td>
                      {{ k }}
                    </td>
                    <td v-for="(str, i) in strs" :key="`${k}-${i}`">
                      {{ str }}
                    </td>
                  </tr>
                </table>

                <tr>
                  <th colspan="2">
                    <b>{{
                      $t("__store_autoreplyordertime_timeintervals_reject")
                    }}</b>
                  </th>
                </tr>
                <table>
                  <tr
                    v-for="(strs, k) in weekTimeIntervalsStrings('reject')"
                    :key="k"
                  >
                    <td>
                      {{ k }}
                    </td>
                    <td v-for="(str, i) in strs" :key="`${k}-${i}`">
                      {{ str }}
                    </td>
                  </tr>
                </table>
              </template>
            </template>
          </table>
        </template>
      </div>
    </v-card-text>

    <v-dialog v-model="formShow" persistent max-width="600px">
      <AutoReplyOrderTimeForm
        :data="autoReplyOrderTimeFormData"
        @ok="formOk"
        @cancel="formCancel"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import AutoReplyOrderTimeForm from "@/components/Store/AutoReplyOrderTimeForm";
import { mapGetters } from "vuex";

export default {
  components: {
    AutoReplyOrderTimeForm
  },
  computed: {
    ...mapGetters({
      isMobile: "isMobile",
      storeAutoReplyOrderTime: "store/autoReplyOrderTime"
    }),
    timeZoneOffset() {
      return this.storeAutoReplyOrderTime
        ? this.storeAutoReplyOrderTime["time_zone_offset"]
        : "";
    },
    mode() {
      return this.storeAutoReplyOrderTime
        ? this.storeAutoReplyOrderTime["mode"]
        : "";
    }
  },
  data() {
    return {
      formShow: false,
      autoReplyOrderTimeFormData: null
    };
  },
  methods: {
    weekTimeIntervalsStrings(action) {
      const strs = {};
      if (this.storeAutoReplyOrderTime) {
        for (let d = 0; d < 7; d++) {
          const timeIntervals = this.storeAutoReplyOrderTime[
            "weekday_time_intervals"
          ][d.toString()];
          let subStrs = [];
          if (timeIntervals) {
            for (const timeInterval of this.storeAutoReplyOrderTime[
              "weekday_time_intervals"
            ][d.toString()]) {
              if (timeInterval["action"] === action) {
                const str = `${timeInterval["start_time"]} ~ ${timeInterval["end_time"]}`;
                subStrs.push(str);
              }
            }
          }
          if (subStrs.length > 0) {
            const key = `(${this.$t(
              `__store_autoreplyordertime_weekday_${d}`
            )})`;
            strs[key] = subStrs;
          }
        }
      }
      return strs;
    },
    toSet() {
      this.autoReplyOrderTimeFormData = this.storeAutoReplyOrderTime;
      this.formShow = true;
    },
    formOk(data) {
      this.$emit("toSet", data);
      this.autoReplyOrderTimeFormData = null;
      this.formShow = false;
    },
    formCancel() {
      this.autoReplyOrderTimeFormData = null;
      this.formShow = false;
    }
  }
};
</script>

<style lang="scss">
.data-container {
  position: relative;

  .set-btn {
    position: absolute;
    top: 0;
    right: 0;
  }
  &__line {
    margin-block: 1em;
  }
  &__header {
    font-size: 1.5em;
    font-weight: bold;
  }
  &__table {
    th {
      padding: 0.2em;
      white-space: nowrap;
      text-align: left;
      vertical-align: top;
    }
    td {
      padding: 0.2em;
      word-wrap: break-word;
    }
  }
}
</style>
