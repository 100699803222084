<template>
  <v-card>
    <v-toolbar color="primary" dark flat>
      <v-toolbar-title>{{ $t("__store_info") }}</v-toolbar-title>
    </v-toolbar>

    <v-card-text>
      <v-form ref="form" v-model="isValid">
        <table class="info-table">
          <!-- name -->
          <tr>
            <th>
              {{ $t("__store_info_name") }}
              <span class="error--text"> *</span>
            </th>
            <td>
              <v-text-field
                outlined
                v-model="form.name"
                required
                :rules="[requiredRule]"
              />
            </td>
          </tr>
          <!-- photo url -->
          <tr>
            <th>{{ $t("__store_info_photo") }}</th>
            <td>
              <photo-url-input outlined v-model="form.photo" />
            </td>
          </tr>
          <!-- description -->
          <tr>
            <th>
              {{ $t("__item_form_description") }}
              <span class="error--text"> *</span>
            </th>
            <td>
              <v-textarea
                outlined
                v-model="form.description"
                required
                :rules="[requiredRule]"
                counter
                maxlength="100"
              />
            </td>
          </tr>
          <!-- introduction -->
          <tr>
            <th>{{ $t("__store_info_introduction") }}</th>
            <td>
              <v-card outlined style="border: 1px solid gray">
                <HTMLEditor v-model="form.introduction" />
              </v-card>
            </td>
          </tr>
          <tr>
            <td colspan="2"><v-divider class="my-4" /></td>
          </tr>
          <!-- customer service policy -->
          <tr>
            <td colspan="2">
              <div class="my-4 text-h5 font-weight-black">
                {{ $t("__store_info_customerservicepolicy") }}
              </div>
            </td>
          </tr>
          <!-- contact us -->
          <tr>
            <th>{{ $t("__store_info_customerservicepolicy_contactus") }}</th>
            <td class="pt-4">
              <v-textarea outlined v-model="form.contactUs" />
            </td>
          </tr>
          <!-- terms and conditions -->
          <tr>
            <th>
              {{ $t("__store_info_customerservicepolicy_termsandconditions") }}
            </th>
            <td class="pt-4">
              <v-textarea outlined v-model="form.termsAndConditions" />
            </td>
          </tr>
          <!-- return policy -->
          <tr>
            <th>{{ $t("__store_info_customerservicepolicy_returnpolicy") }}</th>
            <td class="pt-4">
              <v-textarea outlined v-model="form.returnPolicy" />
            </td>
          </tr>
        </table>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="update" :disabled="!isValid">{{
        $t("__update")
      }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import HTMLEditor from "@/components/HTMLEditor";
import { mapGetters } from "vuex";

export default {
  components: {
    HTMLEditor
  },
  computed: {
    ...mapGetters({
      storeData: "store/data"
    })
  },
  data() {
    return {
      form: {
        name: "",
        photo: "",
        description: "",
        introduction: "",
        contactUs: "",
        termsAndConditions: "",
        returnPolicy: ""
      },
      isValid: true,
      requiredRule: v => !!v || this.$t("__required")
    };
  },
  watch: {
    storeData: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.form.name = newValue && newValue["name"] ? newValue["name"] : "";
        this.form.photo =
          newValue &&
          newValue["photo_urls"] &&
          newValue["photo_urls"].length > 0
            ? newValue["photo_urls"][0]
            : "";
        this.form.description =
          newValue && newValue["description"] ? newValue["description"] : "";
        this.form.introduction = newValue ? newValue["introduction"] : "";
        this.form.contactUs =
          newValue &&
          newValue["customer_service_policy"] &&
          newValue["customer_service_policy"]["contact_us"]
            ? newValue["customer_service_policy"]["contact_us"]
            : "";
        this.form.termsAndConditions =
          newValue &&
          newValue["customer_service_policy"] &&
          newValue["customer_service_policy"]["terms_and_conditions"]
            ? newValue["customer_service_policy"]["terms_and_conditions"]
            : "";
        this.form.returnPolicy =
          newValue &&
          newValue["customer_service_policy"] &&
          newValue["customer_service_policy"]["return_policy"]
            ? newValue["customer_service_policy"]["return_policy"]
            : "";
      }
    }
  },
  methods: {
    update() {
      const photoURLs = [];
      if (this.form.photo.length > 0) {
        photoURLs.push(this.form.photo);
      }
      this.$emit("update", {
        name: this.form.name,
        photoURLs,
        description: this.form.description,
        introduction: this.form.introduction,
        contactUs: this.form.contactUs,
        termsAndConditions: this.form.termsAndConditions,
        returnPolicy: this.form.returnPolicy
      });
    }
  }
};
</script>

<style lang="scss">
.info-table {
  width: 100%;

  th {
    width: 1%;
    white-space: nowrap;
    text-align: left;
    vertical-align: top;
    font-size: 1.2em;
    padding-right: 1em;
    padding-top: 1em;
  }
}
</style>
